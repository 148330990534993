var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isArbeitgeber
    ? _c(
        "div",
        { staticClass: "flex gap-4 items-center justify-center mt-4" },
        [
          !_vm.isArbeitgeber && !_vm.job.beworben
            ? _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.bewerben(_vm.job.id)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.t("Jetzt bewerben")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.job.beworben
            ? _c(
                "button",
                {
                  staticClass: "btn minL:hover:bg-color1",
                  attrs: { disabled: "" }
                },
                [_vm._v(_vm._s(_vm.t("Bereits beworben")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoggedIn && !_vm.isArbeitgeber
            ? _c(
                "button",
                {
                  staticClass: "btn-green btn-round flex items-center",
                  class: {
                    isNotFavorit: !_vm.job.favorit,
                    isFavorit: _vm.job.favorit
                  },
                  on: {
                    click: function($event) {
                      return _vm.merken(_vm.job)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa-heart text-f2 far hoverHeart" }),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa-heart text-f2 fas normalHeart" })
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }