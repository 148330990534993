var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bewerbungen-wrapper" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-center s:grid s:grid-cols-1 s:gap-4"
      },
      [
        _c("div", {}, [
          _c("div", { staticClass: "relative" }, [
            _c("i", { staticClass: "far fa-search search-input-icon" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              staticClass: "search-input",
              attrs: {
                type: "search",
                placeholder: _vm.t("In Job-Alarme suchen"),
                required: ""
              },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  function($event) {
                    return _vm.changePage()
                  }
                ]
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex items-center gap-4" }, [
          _c(
            "label",
            {
              staticClass: "pt-2 no-padding whitespace-nowrap",
              attrs: { for: "order" }
            },
            [_vm._v(_vm._s(_vm.t("Sortieren nach:")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrapper order-select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order,
                    expression: "order"
                  }
                ],
                attrs: { name: "order", id: "order" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.order = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.changePage()
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v(_vm._s(_vm.t("Neuste")))
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v(_vm._s(_vm.t("Älteste")))
                ])
              ]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bewerbungen" },
      _vm._l(_vm.shownBewerbungen, function(alarm, index) {
        return _c("div", { key: index, staticClass: "bewerbung" }, [
          _c(
            "div",
            {
              staticClass:
                "flex gap-4 items-center s:flex-col s:items-start s:pb-4 s:border-color10 s:border-b"
            },
            [
              _c("div", { staticClass: "job-alarm-icon" }),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col gap-2" }, [
                _c("div", { staticClass: "text-f2 font-bold" }, [
                  _vm._v(_vm._s(alarm.title)),
                  alarm.stichwort
                    ? _c("span", [_vm._v(" - " + _vm._s(alarm.stichwort))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex gap-x-4 flex-wrap gap-y-0" }, [
                  alarm.kanton
                    ? _c("div", [
                        _c("i", {
                          staticClass:
                            "fa-sharp fa-solid fa-location-dot text-color5"
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(alarm.kanton) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(alarm.funktion))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(alarm.anstellungsgrad))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex gap-x-4 flex-wrap gap-y-0" }, [
                  _c("div", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.t("Erstellt:")) +
                        "\n              "
                    ),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.formatDate(alarm.datum)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.t("Intervall:")) +
                        "\n              "
                    ),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(alarm.intervall))
                    ])
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex s:justify-end" }, [
            _c(
              "button",
              {
                staticClass:
                  "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                on: {
                  click: function($event) {
                    return _vm.toggleMenu(index)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "far center",
                  class: {
                    "fa-ellipsis-v": _vm.menuIndex != index,
                    "fa-times": _vm.menuIndex == index
                  }
                }),
                _vm._v(" "),
                _vm.menuIndex == index
                  ? _c("div", { staticClass: "circle-dropdown text-color1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "delete-link",
                          on: {
                            click: function($event) {
                              return _vm.removeJobalarm(alarm.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("Löschen")) +
                              "\n              "
                          ),
                          _c("i", { staticClass: "far fa-times" })
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-6" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.overlays.show("overlayJobalarm")
            }
          }
        },
        [_vm._v(_vm._s(_vm.t("Neuen Job-Alarm erstellen")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }