var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notifications",
      on: {
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c("i", { staticClass: "fa fa-bell" }),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [
        _vm._v(_vm._s(_vm.notificationsData.length))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: { hidden: _vm.hover == false } },
        _vm._l(_vm.notificationsData, function(notification) {
          return _c("div", { staticClass: "notification" }, [
            _vm._v(
              "\n       " + _vm._s(JSON.stringify(notification)) + "\n    "
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }