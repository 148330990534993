import { VDirective, VServices } from '@libTs/vue-base';

/**
 * v-flash-hide
 *
 * Automatically hides the element after 4 seconds
 */
export const FlashHideDirective: VDirective = [
  'flashHide',
  {
    bind( el: HTMLDivElement ): void {
      setTimeout(
        () => { el.remove(); },
        4000
      );
    },
  }
];
