


















import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';


export default Vue.extend({
  name: 'akkordeon',
  props: {},
  data() {
    return {
    };
  },
  methods: {
    toggle() {
      const akkordeonContainer = $(this.$refs.akkordeonContainer);
      const akkordeoIcon = $(this.$refs.akkordeonIcon);

      if (!akkordeonContainer.hasClass('akkordeon-active')) {
        $('.akkordeon-container').removeClass('akkordeon-active');
        $('.akkordeon-icon').removeClass('fa-minus');
        $('.akkordeon-icon').addClass('fa-plus');
        akkordeoIcon.removeClass('fa-plus');
        akkordeoIcon.addClass('fa-minus');
        akkordeonContainer.addClass('akkordeon-active');
      } else {
        akkordeoIcon.removeClass('fa-minus');
        akkordeoIcon.addClass('fa-plus');
        akkordeonContainer.removeClass('akkordeon-active');
      }
    },
  },
});
