import { gsap, TweenLite } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as IEnvironment from './env';
gsap.registerPlugin(ScrollTrigger);

const ENV = IEnvironment.ENV;

const animations = {
    //true for fade in animation -> false no animation
    animationDev: true,
    animationStaging: true,
  
    //default value is 1 -> 1 * animationDurationMultiplier
    animationDurationMultiplier: 1,
  
    //0% -> top -> 100% -> bottom
    scrollerPosition: '85%',
    scrollerPositionMobile: '95%',
  
    //positive value -> fade in from bottom | negative value -> fade in from top
    yStartpoint: 120,
    yEndpoint: 0,
  
    //positive value -> fade in from right | negative value -> fade in from left
    xStartpoint: 0,
    xEndpoint: 0,
  
    // play none none reverse -> repeat animation | play none none none -> play animation once
    toggleActions: 'play none none reverse',
  
    //Modules to not animate => Array with classnames  ex. ['webkit-slideshow-container', 'webkit-grid-container']
    modulesToNotAnimate: [''],
  
    //For Debugging 
    activateMarkers: false,  
}

const animationsSlide = {
  //true for fade in animation -> false no animation
  animationDev: true,
  animationStaging: true,

  //default value is 1 -> 1 * animationDurationMultiplier
  animationDurationMultiplier: 1,

  //0% -> top -> 100% -> bottom
  scrollerPosition: '85%',
  scrollerPositionMobile: '95%',

  //positive value -> fade in from bottom | negative value -> fade in from top
  yStartpoint: 0,
  yEndpoint: 0,

  //positive value -> fade in from right | negative value -> fade in from left
  xStartpoint: -1000,
  xEndpoint: 0,

  // play none none reverse -> repeat animation | play none none none -> play animation once
  toggleActions: 'play none none none',

  //Modules to not animate => Array with classnames  ex. ['webkit-slideshow-container', 'webkit-grid-container']
  modulesToNotAnimate: [''],

  //For Debugging 
  activateMarkers: false,  
}

let animationActive = false;
let scrollerPosition = animations.scrollerPosition;

if (ENV.isProduction) {
  if (ENV.name != 'dev' && animations.animationStaging) {
    animationActive = true;
  } else {
    animationActive = false;
  }
} else {
  animationActive = animations.animationDev;
}

if (window.innerWidth <= 480) {
  scrollerPosition = animations.scrollerPositionMobile;
} else {
  scrollerPosition = animations.scrollerPosition;
}

function reloaded() {
  ScrollTrigger.refresh(true);
}

$(window).on('load', reloaded);

if (animationActive) {
  $(() => {
    const firstElIsModularContent = $('.app-main')
      .children()
      .first()
      .children()
      .first()
      .hasClass('fade-in-animation');
    const modularContent = gsap.utils.toArray('.fade-in-animation');

    modularContent.forEach(function(value: HTMLElement, index) {
      const classList = animations.modulesToNotAnimate as Array<string>;
      const child: HTMLElement = value.firstChild as HTMLElement;
      if (classList.some((className) => child.classList.contains(className))) {
        return;
      } else if (firstElIsModularContent && index == 0) {
        return;
      } else {
        const anim = TweenLite.fromTo(
          value,
          {
            autoAlpha: 0,
            y: animations.yStartpoint,
            x: animations.xStartpoint,
          },
          {
            autoAlpha: 1,
            y: animations.yEndpoint,
            x: animations.xEndpoint,
            duration: 1 * animations.animationDurationMultiplier,
            clearProps: 'all',
          }
        );
        ScrollTrigger.create({
          trigger: value,
          start: `top ${scrollerPosition}`,
          animation: anim,
          markers: animations.activateMarkers,
          toggleActions: animations.toggleActions,
        });
      }
    });
  });

  // $(() => {
  //   const firstElIsModularContent = $('.app-main')
  //     .children()
  //     .first()
  //     .children()
  //     .first()
  //     .hasClass('slide-in-animation');
  //   const modularContent = gsap.utils.toArray('.slide-in-animation');

  //   modularContent.forEach(function(value: HTMLElement, index) {
  //     const classList = animationsSlide.modulesToNotAnimate as Array<string>;
  //     const child: HTMLElement = value.firstChild as HTMLElement;
  //     if (classList.some((className) => child.classList.contains(className))) {
  //       return;
  //     } else if (firstElIsModularContent && index == 0) {
  //       return;
  //     } else {
  //       const anim = TweenLite.fromTo(
  //         value,
  //         {
  //           autoAlpha: 0,
  //           y: animationsSlide.yStartpoint,
  //           x: animationsSlide.xStartpoint,
  //         },
  //         {
  //           autoAlpha: 1,
  //           y: animationsSlide.yEndpoint,
  //           x: animationsSlide.xEndpoint,
  //           duration: 1 * animationsSlide.animationDurationMultiplier,
  //           clearProps: 'all',
  //         }
  //       );
  //       ScrollTrigger.create({
  //         trigger: value,
  //         start: `top ${scrollerPosition}`,
  //         animation: anim,
  //         markers: animationsSlide.activateMarkers,
  //         toggleActions: animationsSlide.toggleActions,
  //       });
  //     }
  //   });
  // });
}
