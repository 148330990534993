import { VBaseService, VServices } from '@libTs/vue-base';
import { CRAFT_TRANSLATIONS, SITEURL } from '@libTs/cms.globals';

export class TranslationService extends VBaseService {

  public t( key: string ): string {
    try {
      if ( CRAFT_TRANSLATIONS[ key ] ) {
        return CRAFT_TRANSLATIONS[ key ];
      } else {
        return key;
      }
    } catch ( error ) {
      return key;
    }
  }
}
