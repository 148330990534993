import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { OverlaysService } from './overlays.service';
import { request } from 'http';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class StatusService extends VBaseService {

  public form = {
  };

  public bewerbungId = null;

  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];
  public defaultTexte = {};


  getDefaultText() {
    axios.get(SITEURL + 'actions/infajobs/jobs/get-standardtexte')
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.defaultTexte = response.data;
      }
    } )
    .catch( error => {
      this.isLoading = false;
    } );
  }

  setDefaultText(key) {
    this.form.nachricht = this.defaultTexte[key];
  }

  erhaltBestätigen(e: any) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/erhalt-bestaetigen',
      requestData
    )
    .then(response => {
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Erhalt wurde bestätigt'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    })
    .catch(error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Erhalt konnte nicht bestätigt werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );

      SERVICES.overlays.hide();
    })
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  bewerbungAblehnen(e: any) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/bewerbung-ablehnen',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerbung wurde abgelehnt.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerbung konnte nicht abgelehnt werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
      SERVICES.overlays.hide();
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  bewerberAnstellen(e: any) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/bewerber-einstellen',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerber wurde angestellt.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerber konnte nicht angestellt werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
      SERVICES.overlays.hide();
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  wiederEinladen() {
    // e.preventDefault()

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/wieder-einladen',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerber wurde wieder eingeladen.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerber konnte nicht wieder eingeladen werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  unterlagenAnfordern(e) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/unterlagen-anfordern',
      requestData
    )
    .then( response => {
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Unterlagen wurden angefordert.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Unterlagen konnten nicht angefordert werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
      SERVICES.overlays.hide();
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  unterlagenErhalten() {
    // e.preventDefault()

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/unterlagen-erhalten',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Es wurde bestätigt, dass die Unterlagen erhalten wurden.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Es konnte nicht bestätigt werden, dass die Unterlagen erhalten wurden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }


  umGeduldBitten(e) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/geduld-bitten',
      requestData
    )
    .then( response => {
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Es wurde um Geduld gebeten.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Es konnte nicht um Geduld gebeten werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
      SERVICES.overlays.hide();
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  einladungVorstellungsgespraech(e) {
    e.preventDefault();

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/einladung-vorstellungsgespraech',
      requestData
    )
    .then( response => {
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Einladung wurde gesendet.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Einladung konnte nicht gesendet werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
      SERVICES.overlays.hide();
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  einladungBestätigt() {
    // e.preventDefault()

    const requestData = {
      'bewerbungId': this.bewerbungId,
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/einladung-bestaetigt',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Der Status vom Vorstellungsgespräch wurde auf bestätigt geändert.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Der Status vom Vorstellungsgespräch konnte nicht geändert werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}