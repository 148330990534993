













import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { OverlaysService } from '../services/overlays.service';
import { BewerbungService } from '../services/bewerbung.service';
import { JobalarmService } from '../services/jobalarm.service';
import { JobService } from '../services/job.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';


const SERVICES = VServices({
  translation: TranslationService,
  overlays: OverlaysService,
  bewerbung: BewerbungService,
  jobalarm: JobalarmService,
  job: JobService
});

export default Vue.extend({
  name: 'job-buttons',
  components: {
  },
  props: {
    data: String,
    isLoggedIn: Number,
    isArbeitgeber: Number,
  },
  data() {
    return {
      job: [],

      jobalarm: SERVICES.jobalarm,
      overlays: SERVICES.overlays,
    };
  },
  mounted() {
    this.job = JSON.parse(this.data);


    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-job-by-id?jobId=' + this.job.id)
      .then(response => {
        this.job = response.data;
      })
      .catch( error => {

      });
    });
  },
  methods: {

    bewerben(jobId: number) {
      if(this.isLoggedIn) {
        SERVICES.bewerbung.bewerben(jobId);
      }
      else {
        SERVICES.overlays.show('overlayLogin');
      }
    },
    merken(job: any) {
      if(job.favorit) {
        SERVICES.job.removeFromMerkliste(job.id);
      } 
      else {
        SERVICES.job.addToMerkliste(job.id);
      }
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
