var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "testing-banner",
      class: { showing: _vm.isShown, isShownMobile: _vm.isShownMobile },
      attrs: { href: "mailto:info@infajobs.ch" }
    },
    [
      _c("div", { staticClass: "testing-banner-img" }),
      _vm._v(
        "\n  " + _vm._s(_vm.t("Hilf uns die Webseite zu verbessern!")) + "\n  "
      ),
      _c("i", {
        staticClass: "fas fa-times ml-4 text-f2",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.removeBanner()
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }