

















































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { JobService } from '../services/job.service';
import { JobalarmService } from '../services/jobalarm.service';
import { BewerbungService } from '../services/bewerbung.service';
import { OverlaysService } from '../services/overlays.service';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import axios from 'axios';


const SERVICES = VServices({
  translation: TranslationService,
  overlays: OverlaysService,
  bewerbung: BewerbungService,
  jobalarm: JobalarmService,
  job: JobService
});

export default Vue.extend({
  name: 'jobs-job',
  props: {
    data: String,
    isLoggedIn: Number,
    isArbeitgeber: Number,
    jobId: Number
  },
  data() {
    return {
      hover: false,
      jobsData: [],
      shownJobs: [],
      jobalarm: SERVICES.jobalarm,
      overlays: SERVICES.overlays,
      baseUrl: BASEURL,
      siteUrl: SITEURL,
    };
  },
  mounted() {
    this.jobsData = JSON.parse(this.data);

    this.shownJobs = this.jobsData.slice(0, 2);

    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-job-jobs?jobId=' + this.jobId)
      .then(response => {
        this.jobsData = response.data;

        this.shownJobs = this.jobsData.slice(0, 2);

      })
      .catch( error => {

      });
    });
  },
  methods: {
    bewerben(jobId: number) {
      if(this.isLoggedIn) {
        SERVICES.bewerbung.bewerben(jobId);
      }
      else {
        SERVICES.overlays.show('overlayLogin');
      }
    },
    merken(job: any) {
      if(job.favorit) {
        SERVICES.job.removeFromMerkliste(job.id);
      } 
      else {
        SERVICES.job.addToMerkliste(job.id);
      }
    },
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
