var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "border-b border-color5 pb-8" }, [
        _c("h1", { staticClass: "text-f2 font-bold" }, [
          _vm._v(_vm._s(_vm.t("Bezahlte Inserate")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bewerbungen-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "flex justify-between items-center s:grid s:grid-cols-1 s:gap-4"
          },
          [
            _c("div", {}, [
              _c("div", { staticClass: "relative" }, [
                _c("i", { staticClass: "far fa-search search-input-icon" }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "search-input",
                  attrs: {
                    type: "search",
                    placeholder: _vm.t("In Paketen suchen"),
                    required: ""
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      },
                      function($event) {
                        return _vm.changePage()
                      }
                    ]
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex items-center gap-4" }, [
              _c(
                "label",
                {
                  staticClass: "pt-2 no-padding whitespace-nowrap",
                  attrs: { for: "order" }
                },
                [_vm._v(_vm._s(_vm.t("Sortieren nach:")))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "select-wrapper order-select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.order,
                        expression: "order"
                      }
                    ],
                    attrs: { name: "order", id: "order" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.order = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.changePage()
                        }
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v(_vm._s(_vm.t("Neuste")))
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v(_vm._s(_vm.t("Älteste")))
                    ])
                  ]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bewerbungen" },
          [
            _vm._l(_vm.shownBewerbungen, function(job, index) {
              return _c(
                "div",
                { key: index, staticClass: "job-mit-bewerbung px-4" },
                [
                  _c("div", { staticClass: "flex flex-col gap-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-between s:flex-col" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-col gap-2 s:pb-4 s:border-color10 s:border-b s:mb-4"
                          },
                          [
                            _c("div", { staticClass: "text-f2 font-bold" }, [
                              _vm._v(
                                _vm._s(job.title) +
                                  " - " +
                                  _vm._s(_vm.t("Job-Paket")) +
                                  " \n                  "
                              ),
                              job.title == "Free"
                                ? _c("span", { staticClass: "text-f3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.t("(Gültig bis 31. Dezember 2024)")
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex gap-x-4 mb-2 flex-wrap" },
                              [
                                _c("div", [_vm._v(_vm._s(job.id))]),
                                _vm._v(" "),
                                job.dauer
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-hourglass-half text-color5"
                                      }),
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.t("Stellenanzeige für")) +
                                          " " +
                                          _vm._s(job.dauer) +
                                          " " +
                                          _vm._s(_vm.t("Tage")) +
                                          "\n                  "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex gap-x-4 mb-2 flex-wrap" },
                              [
                                job.anzahl > 0
                                  ? _c("div", { staticClass: "status aktiv" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.t("Aktiv")) +
                                          "\n                  "
                                      )
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "status inaktiv" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.t("Inaktiv")) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "text-color8" }, [
                                    _vm._v(_vm._s(_vm.t("Total Inserate:")))
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(job.gesamtAnzahl) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "text-color8" }, [
                                    _vm._v(_vm._s(_vm.t("Übrige Inserate:")))
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(job.anzahl) +
                                      "\n                  "
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-start h-full grow-0 s:justify-end"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleAkkordeon(index)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-chevron-down center",
                                  class: {
                                    hidden: _vm.ausgeklapptIndex == index
                                  }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "far fa-chevron-up center",
                                  class: {
                                    hidden: _vm.ausgeklapptIndex != index
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-col gap-4",
                        class: { hidden: _vm.ausgeklapptIndex != index }
                      },
                      [
                        _vm._l(job.jobs, function(bewerbung, bewerbungIndex) {
                          return _c(
                            "div",
                            {
                              key: bewerbungIndex,
                              staticClass:
                                "shadow rounded-lg p-4 flex justify-between gap-4 s:flex-col"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex gap-4 s:pb-4 s:border-color10 s:border-b"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-col gap-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-f2 font-bold" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(bewerbung.name) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex gap-y-2 gap-x-4 flex-wrap"
                                        },
                                        [
                                          bewerbung.funktion
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(bewerbung.funktion) +
                                                    "\n                      "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          bewerbung.kanton
                                            ? _c("div", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa-sharp fa-solid fa-location-dot text-color5"
                                                }),
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(bewerbung.kanton) +
                                                    "\n                      "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex s:justify-end" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleMenu(bewerbungIndex)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far center",
                                      class: {
                                        "fa-ellipsis-v":
                                          _vm.menuIndex != bewerbungIndex,
                                        "fa-times":
                                          _vm.menuIndex == bewerbungIndex
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.menuIndex == bewerbungIndex
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "circle-dropdown text-color1"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "minL:hover:text-color5 cursor-pointer text-left",
                                                attrs: { href: bewerbung.url }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.t("Inserat ansehen")
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "minL:hover:text-color5 cursor-pointer text-left",
                                                attrs: {
                                                  href:
                                                    _vm.baseUrl +
                                                    "arbeitgeber/job-bearbeiten?jobId=" +
                                                    bewerbung.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.t(
                                                        "Inserat bearbeiten"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        job.jobs.length <= 0
                          ? _c("div", { staticClass: "w-full h-24 relative" }, [
                              _c("div", { staticClass: "center" }, [
                                _vm._v(_vm._s(_vm.t("Zur Zeit keine Inserate")))
                              ])
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _vm.shownBewerbungen.length <= 0 && !_vm.search
              ? _c("div", { staticClass: "w-full h-24 relative mt-8" }, [
                  _c("div", { staticClass: "center" }, [
                    _vm._v(_vm._s(_vm.t("Du hast derzeit kein Paket gelöst")))
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "border-b border-color5 pb-8" }, [
        _c("h1", { staticClass: "text-f2 font-bold" }, [
          _vm._v(_vm._s(_vm.t("Deine Pakete")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-8 grid grid-cols-2 s:grid-cols-1 gap-8" },
        [
          _vm._l(_vm.shownBewerbungen, function(paket, index) {
            return _c(
              "div",
              { key: index, staticClass: "paket-card bg-color5 text-color1" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col pb-4 mb-4 border-b border-color1"
                  },
                  [
                    _c("div", { staticClass: "text-f1 font-bold" }, [
                      _vm._v(
                        "\n            " + _vm._s(paket.title) + "\n          "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-col gap-2" }, [
                  paket.title == "Free"
                    ? _c("div", { staticClass: "flex gap-2" }, [
                        _c("i", {
                          staticClass: "fas fa-exclamation-circle mt-1"
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.t("Gültig bis 31. Dezember 2024")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(paket.gesamtAnzahl) +
                        " " +
                        _vm._s(
                          paket.gesamtAnzahl > 1
                            ? _vm.t("Stellenausschreibungen")
                            : _vm.t("Stellenausschreibung")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("Stellenanzeige für")) +
                        " " +
                        _vm._s(paket.dauer) +
                        " " +
                        _vm._s(_vm.t("Tage")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("Voller Zugriff auf alle Funktionen")) +
                        "\n          "
                    )
                  ])
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.shownBewerbungen.length <= 0 && !_vm.search
            ? _c("div", { staticClass: "w-full col-span-2 h-24 relative" }, [
                _c("div", { staticClass: "center" }, [
                  _vm._v(_vm._s(_vm.t("Du hast derzeit kein Paket gelöst")))
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "border-b border-color5 pb-8" }, [
        _c("h1", { staticClass: "text-f2 font-bold" }, [
          _vm._v(_vm._s(_vm.t("Weitere Pakete")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-8 grid grid-cols-2 s:grid-cols-1 gap-8" },
        _vm._l(_vm.products, function(paket, index) {
          return _c(
            "div",
            { key: index, staticClass: "paket-card bg-color2 text-color1" },
            [
              _c(
                "div",
                {
                  staticClass: "flex flex-col pb-2 mb-2 border-b border-color1"
                },
                [
                  _c("div", { staticClass: "text-f1 font-bold" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("CHF")) +
                        " " +
                        _vm._s(paket.preis.toFixed(2)) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _vm._v(
                      "\n            " + _vm._s(paket.title) + "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col gap-2 pb-4 mb-2 border-b border-color1"
                },
                [
                  paket.title == "Free"
                    ? _c("div", { staticClass: "flex gap-2" }, [
                        _c("i", {
                          staticClass: "fas fa-exclamation-circle mt-1"
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.t("Gültig bis 31. Dezember 2024")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(paket.anzahl) +
                        " " +
                        _vm._s(
                          paket.anzahl > 1
                            ? _vm.t("Stellenausschreibungen")
                            : _vm.t("Stellenausschreibung")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("Stellenanzeige für")) +
                        " " +
                        _vm._s(paket.dauer) +
                        " " +
                        _vm._s(_vm.t("Tage")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("i", { staticClass: "fas fa-check-circle mt-1" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("Voller Zugriff auf alle Funktionen")) +
                        "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-gray",
                    on: {
                      click: function($event) {
                        return _vm.addToCart(paket.purchasableId)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.t("In den Warenkorb")))]
                )
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }