







































































































































































































































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { StatusService } from '../services/status.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { OverlaysService } from '../services/overlays.service';


const SERVICES = VServices({
  translation: TranslationService,
  status: StatusService,
  overlays: OverlaysService
});

export default Vue.extend({
  name: 'arbeitgeber-bewerbungen',
  props: {
    data: String
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 6,
      menuIndex: null,
      ausgeklapptIndex: null,
      selectedStatus: null
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);
    let sortedArray = this.bewerbungenData.sort((a, b) => {
      if(a.veroeffentlichung < b.veroeffentlichung) {
        return 1;
      }
      if(a.veroeffentlichung > b.veroeffentlichung) {
        return -1;
      }
      return 0;
    });
    this.shownBewerbungen = sortedArray.slice(0, this.recordsPerPage);

    this.pages = Math.ceil(this.bewerbungenData.length / this.recordsPerPage);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('bewerbung')) {
      sortedArray.forEach((bewerbung: any, index: number) => {
        if(bewerbung.id == urlParams.get('bewerbung')) {
          console.log(index);
          if(index + 1 > this.recordsPerPage) {
            this.changePage(Math.floor(index/this.recordsPerPage) + 1);
            this.toggleAkkordeon(index % this.recordsPerPage);
          }
          else {
            this.toggleAkkordeon(index);
          }
        }
      });
    }



    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-jobausschreibungen-mit-bewerbungen')
      .then(response => {
        console.log(response.data)

        this.bewerbungenData = response.data;

        this.changePage();
      })
      .catch( error => {

      });
    });
  },
  methods: {
    toggleMenu(index) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    toggleAkkordeon(index) {
      if(this.ausgeklapptIndex == index) {
        this.ausgeklapptIndex = null;
        this.selectedStatus = null;
      }
      else {
        this.ausgeklapptIndex = index;
        this.selectedStatus = '';

        setTimeout(function() {
          const id = 'akkordeon' + index;
          const yOffset = -150; 
          const element = document.getElementById(id);
          if(element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }, 100);        
      }
    },
    setStatus(index, status) {
      this.ausgeklapptIndex = index;

      if(this.selectedStatus == status) {
        this.selectedStatus = '';
      }
      else {
        this.selectedStatus = status;
      }
    },

    filterBewerbungen(bewerbungen: any, status: string) {
      return bewerbungen.filter((element: any) => {
        return element.status.includes(status) || status == '';
      });
    },

    downloadBewerbung(bewerbungId: any, kandidat: any) {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-bewerbung-zip?bewerbungId=' + bewerbungId)
      .then(response => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/zip;base64," + response.data; //Image Base64 Goes here
        a.download = "Bewerbungsunterlagen-" + kandidat.vorname + "_" + kandidat.nachname + ".zip"; //File name Here
        a.click(); //Downloaded file

        this.changePage();
      })
      .catch( error => {
        
      });
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.bewerbungenData.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage(page = this.currentPage) {
      this.currentPage = page;

      this.ausgeklapptIndex = null;
      this.selectedStatus = null;

      let sortedArray = this.bewerbungenData.sort((a, b) => {
        if(this.order == 0) {
          if(a.veroeffentlichung < b.veroeffentlichung) {
            return 1;
          }
          if(a.veroeffentlichung > b.veroeffentlichung) {
            return -1;
          }
        }
        else {
          if(a.veroeffentlichung < b.veroeffentlichung) {
            return -1;
          }
          if(a.veroeffentlichung > b.veroeffentlichung) {
            return 1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element) => {
        if(element.title.toUpperCase().includes(this.search.toUpperCase())) {
          return true;
        }

        let isMatching = false;
        element.bewerbungen.forEach((bewerbung: any) => {
          if(
            (bewerbung.kandidat.nachname + ' ' + bewerbung.kandidat.vorname).toUpperCase().includes(this.search.toUpperCase()) ||
            (bewerbung.kandidat.vorname + ' ' + bewerbung.kandidat.nachname).toUpperCase().includes(this.search.toUpperCase())
          ) {
            isMatching = true;
          }
        });

        return isMatching;
      });

      window.scrollTo(0, 0);
      this.shownBewerbungen = searchArray.slice((this.currentPage - 1) * this.recordsPerPage, this.currentPage * this.recordsPerPage);
    },

    erhaltBestätigen(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('erhaltBestaetigen');
      SERVICES.overlays.show('overlayErhaltBestaetigen');
    },

    bewerbungAblehnen(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('bewerbungAblehnen');
      SERVICES.overlays.show('overlayBewerbungAblehnen');
    },

    wiederEinladen(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.wiederEinladen()
    },

    unterlagenAnfordern(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('unterlagenAnfordern');
      SERVICES.overlays.show('overlayUnterlagenAnfordern');
    },
    unterlagenErhalten(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.unterlagenErhalten();
    },

    geduldBitten(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('umGeduldBitten');
      SERVICES.overlays.show('overlayGeduldBitten');
    },
    einladungSenden(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('einladungVorstellungsgespraech');
      SERVICES.overlays.show('overlayEinladungVorstellungsgespraech');
    },

    bewerberEinstellen(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.setDefaultText('bewerberEinstellen');
      SERVICES.overlays.show('overlayKandidatEinstellen');
    },

    einladungBestätigen(bewerbungId: any) {
      SERVICES.status.bewerbungId = bewerbungId;
      SERVICES.status.einladungBestätigt();
    },


    formatDate(dateString) {
      let split = dateString.split(" ");
      let date = split[0];

      let dateParts = date.split("-")
      
      return [
        this.padTo2Digits(dateParts[2]),
        this.padTo2Digits(dateParts[1]),
        dateParts[0],
      ].join('.');
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
