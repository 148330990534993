import { VDirective, VServices } from '@libTs/vue-base';
import { OverlaysService } from '../services/overlays.service';

const SERVICES = VServices( { overlays: OverlaysService } );

export const LangSwitcherDirective: VDirective = [
  'langSwitcher',
  {
    bind( el: HTMLElement ): void {
      $( el ).on( 'mouseover', () => {
        // hide all overlays
        // SERVICES.overlays.hide();

        const angle = $( el ).find( 'i' );

        angle.removeClass( 'fa-angle-down' );
        angle.addClass( 'fa-angle-up' );


        const elToToggle = $( el ).children( 'ul' ).first();
        if ( elToToggle[ 0 ] ) {
          elToToggle.removeClass( 'not-active' );
        }
      } );

      $( el ).on( 'mouseleave', () => {
        // hide all overlays
        // SERVICES.overlays.hide();

        const angle = $( el ).find( 'i' );

        angle.addClass( 'fa-angle-down' );
        angle.removeClass( 'fa-angle-up' );
      

        const elToToggle = $( el ).children( 'ul' ).first();
        if ( elToToggle[ 0 ] ) {
          elToToggle.addClass( 'not-active' );
        }
      } );
    },
  },
];
