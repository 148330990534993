































































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { BewerbungService } from '../services/bewerbung.service';
import { MeetingService } from '../services/meeting.service';
import { LoadingService } from '../services/loading.service';


const SERVICES = VServices({
  translation: TranslationService,
  bewerbung: BewerbungService,
  meeting: MeetingService,
  loading: LoadingService
});

export default Vue.extend({
  name: 'kandidat-bewerbungen',
  props: {
    data: String
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 10,
      menuIndex: null
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);
    
    console.log(this.bewerbungenData);

    let sortedArray = this.bewerbungenData.sort((a, b) => {
      const aCompare = String(a.nachrichten[a.nachrichten.length - 1].datum) + String(a.nachrichten[a.nachrichten.length - 1].zeit);
      const bCompare = String(b.nachrichten[b.nachrichten.length - 1].datum) + String(b.nachrichten[b.nachrichten.length - 1].zeit);

      if(aCompare < bCompare) {
        return -1;
      }
      if(aCompare > bCompare) {
        return 1;
      }
      return 0;
    });
    this.shownBewerbungen = sortedArray.slice(0, this.recordsPerPage);

    this.pages = Math.ceil(this.bewerbungenData.length / this.recordsPerPage);

      window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-meetings')
      .then(response => {
        this.bewerbungenData = response.data;

        this.changePage();
      })
      .catch( error => {

      });
    });
  },
  methods: {
    toggleMenu(index) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.bewerbungenData.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage() {
      let sortedArray = this.bewerbungenData.sort((a, b) => {
        const aCompare = String(a.nachrichten[a.nachrichten.length - 1].datum) + String(a.nachrichten[a.nachrichten.length - 1].zeit);
        const bCompare = String(b.nachrichten[b.nachrichten.length - 1].datum) + String(b.nachrichten[b.nachrichten.length - 1].zeit);

        if(this.order == 0) {
          if(aCompare < bCompare) {
            return -1;
          }
          if(aCompare > bCompare) {
            return 1;
          }
        }
        else {
          if(aCompare < bCompare) {
            return 1;
          }
          if(aCompare > bCompare) {
            return -1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element) => {
        return element.job.toUpperCase().includes(this.search.toUpperCase());
      });

      this.shownBewerbungen = searchArray.slice((this.currentPage - 1) * this.recordsPerPage, this.recordsPerPage)
    },

    removeBewerbung(bewerbungId: any) {
      this.isLoading = true;

      const requestData = {
        'bewerbungId': bewerbungId
      };


      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/remove-bewerbung',
        requestData
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;

          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Bewerbung wurde gelöscht.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.dispatchEvent( new CustomEvent( 'reload' ) );
        }
      } )
      .catch( error => {
        this.isLoading = false;
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerbung konnte nicht gelöscht werden.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    },

    detailsAnsehen(meeting: any) {
      SERVICES.meeting.ansehenArbeitgeber(meeting);
    },

    formatDate(dateString) {
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    },

    getDay(dateString) {
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return this.padTo2Digits(date.getDate());
    },

    getMonthShort(dateString) {
      const months = [
        this.t("Jan"),
        this.t("Feb"),
        this.t("Mär"),
        this.t("Apr"),
        this.t("Mai"),
        this.t("Jun"),
        this.t("Jul"),
        this.t("Aug"),
        this.t("Sep"),
        this.t("Okt"),
        this.t("Nov"),
        this.t("Dez")
      ];
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return months[date.getMonth()];
    },

    getYear(dateString) {
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return date.getFullYear();
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
