/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 *
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { createVueApp } from '@libTs/vue-base';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { BASEURL, SITEURL } from '@libTs/cms.globals';
// import { SliderHelper } from './helpers/slider.helper';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { OverlaysService } from './services/overlays.service';
import { TranslationService } from './services/translation.service';

import { ProfileService } from './services/profile.service';
import { RegisterService } from './services/register.service';
import { LoginService } from './services/login.service';
import { JobausschreibungService } from './services/jobausschreibung.service';
import { BewerbungService } from './services/bewerbung.service';
import { FlashService } from './services/flash.service';
import { JobalarmService } from './services/jobalarm.service';
import { SpringeralarmService } from './services/springeralarm.service';
import { JobService } from './services/job.service';
import { StatusService } from './services/status.service';
import { MeetingService } from './services/meeting.service';
import { BestaetigenService } from './services/bestaetigen.service';
import { LoadingService } from './services/loading.service';

import { OverlayDirective } from './directives/v-overlay.directive';
import { LangSwitcherDirective } from './directives/v-lang-switcher.directive';
import { FlashHideDirective } from './directives/v-flash-hide.directive';
import { LoadedDirective } from './directives/v-loaded.directive';
import 'img-comparison-slider';

import VueGallerySlideshow from 'vue-gallery-slideshow';
import Akkordeon from './components/akkordeon.vue';
import Lightbox from './components/lightbox.vue';
import ArbeitgeberNav from './components/arbeitgeber-nav.vue';
import KandidatNav from './components/kandidat-nav.vue';
import Notifications from './components/notifications.vue';
import JobsFilter from './components/jobs-filter.vue';
import JobsSearch from './components/jobs-search.vue';
import HomeSearch from './components/home-search.vue';
import KandidatBewerbungen from './components/kandidat-bewerbungen.vue';
import KandidatMeetings from './components/kandidat-meetings.vue';
import ArbeitgeberMeetings from './components/arbeitgeber-meetings.vue';
import ArbeitgeberBewerbungen from './components/arbeitgeber-bewerbungen.vue';
import ArbeitgeberInserate from './components/arbeitgeber-inserate.vue';
import ArbeitgeberPakete from './components/arbeitgeber-pakete.vue';
import ArbeitgeberNeusteBewerbungen from './components/arbeitgeber-neuste-bewerbungen.vue';
import KandidatJobalarme from './components/kandidat-jobalarme.vue';
import Springeralarme from './components/springeralarme.vue';
import KandidatMerkliste from './components/kandidat-merkliste.vue';
import KandidatNeusteBewerbungen from './components/kandidat-neuste-bewerbungen.vue';
import Job from './components/job.vue';
import JobButtons from './components/job-buttons.vue';
import JobsJob from './components/jobs-job.vue';
import JobsArbeitgeber from './components/jobs-arbeitgeber.vue';
import ScrollTop from './components/scroll-top.vue';
import TestingBanner from './components/testing-banner.vue';
import VueSlider from 'vue-slider-component';
import Vue2Editor from "vue2-editor";

import Multiselect from 'vue-multiselect'
import * as GmapVue from 'vue2-google-maps'

import './animations';

// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app


Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAJnqu-NAdt6fzHshrzNkugYamuGksMirg',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})


Vue.component('google-maps-map', GmapVue.Map);
Vue.component('google-maps-marker', GmapVue.Marker);
Vue.component('vue-google-autocomplete', GmapVue.Autocomplete);

Vue.use(Vue2Editor);

const app = createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#vue-app',
    services: [
      OverlaysService,
      TranslationService,
      ProfileService,
      RegisterService,
      LoginService,
      JobausschreibungService,
      BewerbungService,
      FlashService,
      JobalarmService,
      SpringeralarmService,
      JobService,
      StatusService,
      MeetingService,
      BestaetigenService,
      LoadingService
    ],
    directives: [
      OverlayDirective,
      LangSwitcherDirective,
      FlashHideDirective,
      LoadedDirective
    ],
    components: {
      VueGallerySlideshow,
      Lightbox,
      Akkordeon,
      ArbeitgeberNav,
      KandidatNav,
      Notifications,
      JobsFilter,
      JobsSearch,
      Job,
      JobButtons,
      JobsJob,
      JobsArbeitgeber,
      HomeSearch,
      Multiselect,
      KandidatBewerbungen,
      KandidatJobalarme,
      Springeralarme,
      KandidatMerkliste,
      KandidatMeetings,
      KandidatNeusteBewerbungen,
      ArbeitgeberMeetings,
      ArbeitgeberBewerbungen,
      ArbeitgeberInserate,
      ArbeitgeberPakete,
      ArbeitgeberNeusteBewerbungen,
      VueSlider,
      ScrollTop,
      TestingBanner
    },
    modules: []
  }
);

Vue.config.productionTip = false;

// Tell Vue that the web component is present.
Vue.config.ignoredElements = [/img-comparison-slider/];

// Add Axios plugin for AJAX requests
Vue.use( VueAxios, axios );


$( () => {
  gsap.registerPlugin( ScrollToPlugin );
} );

gsap.registerPlugin( ScrollTrigger );

function reloaded() {
  ScrollTrigger.refresh( true );
}
$( window ).on( 'load', reloaded );

// -----------------------------------------------------------
// Slick Carousel initialization

// const sliderHelper = new SliderHelper( '.slider' );

// sliderHelper.initSliders();

