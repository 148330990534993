
















































import Vue from 'vue';
import { BASEURL, SITEURL } from '@libTs/cms.globals';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import axios from "axios";

const SERVICES = VServices({
  translation: TranslationService,
});

export default Vue.extend({
  name: 'arbeitgeberNav',
  props: {
    user: String
  },
  data() {
    return {
      hover: false,
      userData: {
        name: '',
        profilbild: ''
      },
      baseUrl: BASEURL,
      siteUrl: SITEURL,
    };
  },
  mounted() {
    console.log(this.user);
    this.userData = JSON.parse(this.user);
    console.log(this.userData);

  },
  methods: {
    t(str: string) {
      return SERVICES.translation.t(str);
    },

    logout() {
      axios.get( this.siteUrl + 'actions/infajobs/user/logout' )
      .then( response => {
        if (response.data) {
          window.location.replace(this.siteUrl);
        }
      }).catch( error => {
    
      });
    }
  },
});
