









































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { StatusService } from '../services/status.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { OverlaysService } from '../services/overlays.service';
import { BewerbungService } from '../services/bewerbung.service';
import { BestaetigenService } from '../services/bestaetigen.service';
import { LoadingService } from '../services/loading.service';

const SERVICES = VServices({
  translation: TranslationService,
  status: StatusService,
  overlays: OverlaysService,
  bewerbung: BewerbungService,
  bestaetigen: BestaetigenService,
  loading: LoadingService
});

export default Vue.extend({
  name: 'kandidat-neuste-bewerbungen',
  props: {
    data: String,
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 6,
      menuIndex: null,
      ausgeklapptIndex: null,
      selectedStatus: null,
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);

    this.shownBewerbungen = this.bewerbungenData;

    window.addEventListener('reload', (e: any) => {
      axios
        .get(SITEURL + 'actions/infajobs/jobs/get-new-bewerbungen-kandidat')
        .then((response) => {
          console.log(response.data);

          this.bewerbungenData = response.data;

          this.shownBewerbungen = this.bewerbungenData;
        })
        .catch((error) => {});
    });
  },
  methods: {
    toggleMenu(index: any) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.bewerbungenData.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage() {
      let sortedArray = this.bewerbungenData.sort((a, b) => {
        if(this.order == 0) {
          if(a.bewerbungsdatum < b.bewerbungsdatum) {
            return -1;
          }
          if(a.bewerbungsdatum > b.bewerbungsdatum) {
            return 1;
          }
        }
        else {
          if(a.bewerbungsdatum < b.bewerbungsdatum) {
            return 1;
          }
          if(a.bewerbungsdatum > b.bewerbungsdatum) {
            return -1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element) => {
        return element.job.name.toUpperCase().includes(this.search.toUpperCase());
      });

      this.shownBewerbungen = searchArray.slice((this.currentPage - 1) * this.recordsPerPage, this.recordsPerPage)
    },

    removeBewerbung(bewerbungId: any) {
      SERVICES.bestaetigen.overlay(this.t('Bewerbung löschen'), this.t('Bist du sicher, dass du die Bewerbung löschen willst?'), this.t('Löschen'), () => {
        const requestData = {
          'bewerbungId': bewerbungId
        };


        SERVICES.loading.startLoading();
        axios.post(
          SITEURL + 'actions/infajobs/jobs/remove-bewerbung',
          requestData
        )
        .then( response => {
          console.log( response );
          if ( response.status == 200 ) {
            this.isLoading = false;

            const flash = new CustomEvent( 'flash', {
              detail: {
                text: this.t('Bewerbung wurde gelöscht.'),
                class: 'success'
              }
            } );
            window.dispatchEvent( flash );

            window.dispatchEvent( new CustomEvent( 'reload' ) );
          }
        } )
        .catch( error => {
          this.isLoading = false;
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Bewerbung konnte nicht gelöscht werden.'),
              class: 'error'
            }
          } );
          window.dispatchEvent( flash );
        } )
        .finally(() => {
          SERVICES.loading.stopLoading();
        });
      });
    },

    bewerbungAnsehen(jobId: any) {
      SERVICES.bewerbung.ansehen(jobId);
    },

    formatDate(dateString) {
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
