












































































































































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { StatusService } from '../services/status.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { OverlaysService } from '../services/overlays.service';
import { LoadingService } from '../services/loading.service';


const SERVICES = VServices({
  translation: TranslationService,
  status: StatusService,
  overlays: OverlaysService,
  loading: LoadingService
});

export default Vue.extend({
  name: 'arbeitgeber-pakete',
  props: {
    data: String,
    products: Array
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 6,
      menuIndex: null,
      ausgeklapptIndex: null,
      selectedStatus: null,
      baseUrl: BASEURL,
      siteUrl: SITEURL
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);
    let sortedArray = this.bewerbungenData.sort((a, b) => {
      if(a.datum < b.datum) {
        return -1;
      }
      if(a.datum > b.datum) {
        return 1;
      }
      return 0;
    });
    this.shownBewerbungen = sortedArray;

    this.pages = Math.ceil(this.bewerbungenData.length / this.recordsPerPage);

    // window.addEventListener( 'reload', ( e: any ) => {
    //   axios.get(SITEURL + 'actions/infajobs/jobs/get-jobausschreibungen-mit-bewerbungen')
    //   .then(response => {
    //     console.log(response.data)

    //     this.bewerbungenData = response.data;

    //     this.changePage();
    //   })
    //   .catch( error => {

    //   });
    // });
  },
  methods: {
    addToCart(purchasableId) {
      SERVICES.loading.startLoading();
      axios.post(
        `${SITEURL}actions/infajobs/shop/add-to-cart`,
        {
          productId: purchasableId,
        }
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          window.location.href = `${SITEURL}cart`;
        }
      } )
      .catch( error => {
        this.isLoading = false;
        this.errorArray = [];
        this.errorArray.push(this.t('Produkt konnte nicht zu Warenkorb hinzugefügt werden.'));
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    },

    toggleMenu(index) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    toggleAkkordeon(index) {
      if(this.ausgeklapptIndex == index) {
        this.ausgeklapptIndex = null;
        this.selectedStatus = null;
      }
      else {
        this.ausgeklapptIndex = index;
        this.selectedStatus = '';
      }
    },

    changePage(page = this.currentPage) {
      this.currentPage = page;

      let sortedArray = this.bewerbungenData.sort((a, b) => {
        if(this.order == 0) {
          if(a.datum < b.datum) {
            return -1;
          }
          if(a.datum > b.datum) {
            return 1;
          }
        }
        else {
          if(a.datum < b.datum) {
            return 1;
          }
          if(a.datum > b.datum) {
            return -1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element) => {
        if(element.title.toUpperCase().includes(this.search.toUpperCase())) {
          return true;
        }

        return false;
      });

      this.shownBewerbungen = searchArray;
    },

    formatDate(dateString) {
      let date = new Date(dateString);
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
