import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService
} );

export class BestaetigenService extends VBaseService {

  public titel: String = '';
  public text: String = '';
  public buttonText: String = '';

  public callBackFunktion: Function = function(){};

  public overlay(titel: String, text: String, buttonText: String, callBackFunktion: Function) {
    this.titel = titel;
    this.text = text;
    this.buttonText = buttonText;
    this.callBackFunktion = callBackFunktion;

    SERVICES.overlays.show('overlayBestaetigen');
  }

  public runFunction() {
    this.callBackFunktion();
    SERVICES.overlays.hide();
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}