















































































































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { StatusService } from '../services/status.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { OverlaysService } from '../services/overlays.service';
import { BestaetigenService } from '../services/bestaetigen.service';
import { LoadingService } from '../services/loading.service';


const SERVICES = VServices({
  translation: TranslationService,
  status: StatusService,
  overlays: OverlaysService,
  bestaetigen: BestaetigenService,
  loading: LoadingService
});

export default Vue.extend({
  name: 'arbeitgeber-inserate',
  props: {
    data: String
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 6,
      menuIndex: null,
      ausgeklapptIndex: null,
      selectedStatus: null,
      baseUrl: BASEURL,
      siteUrl: SITEURL,
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);
    let sortedArray = this.bewerbungenData.sort((a, b) => {
      if(a.veroeffentlichung < b.veroeffentlichung) {
        return 1;
      }
      if(a.veroeffentlichung > b.veroeffentlichung) {
        return -1;
      }
      return 0;
    });
    this.shownBewerbungen = sortedArray.slice(0, this.recordsPerPage);

    this.pages = Math.ceil(this.bewerbungenData.length / this.recordsPerPage);

    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-jobausschreibungen-mit-bewerbungen')
      .then(response => {
        console.log(response.data)

        this.bewerbungenData = response.data;

        this.changePage();
      })
      .catch( error => {

      });
    });
  },
  methods: {
    toggleMenu(index) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    filterBewerbungen(bewerbungen: any, status: string) {
      return bewerbungen.filter((element: any) => {
        return element.status.includes(status) || status == '';
      });
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.bewerbungenData.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage(page = this.currentPage) {
      this.currentPage = page;

      let sortedArray = this.bewerbungenData.sort((a, b) => {
        if(this.order == 0) {
          if(a.veroeffentlichung < b.veroeffentlichung) {
            return 1;
          }
          if(a.veroeffentlichung > b.veroeffentlichung) {
            return -1;
          }
        }
        else {
          if(a.veroeffentlichung < b.veroeffentlichung) {
            return -1;
          }
          if(a.veroeffentlichung > b.veroeffentlichung) {
            return 1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element) => {
        if(element.title.toUpperCase().includes(this.search.toUpperCase())) {
          return true;
        }

        let isMatching = false;
        element.bewerbungen.forEach((bewerbung: any) => {
          if(
            (bewerbung.kandidat.nachname + ' ' + bewerbung.kandidat.vorname).toUpperCase().includes(this.search.toUpperCase()) ||
            (bewerbung.kandidat.vorname + ' ' + bewerbung.kandidat.nachname).toUpperCase().includes(this.search.toUpperCase())
          ) {
            isMatching = true;
          }
        });

        return isMatching;
      });

      this.shownBewerbungen = searchArray.slice((this.currentPage - 1) * this.recordsPerPage, this.currentPage * this.recordsPerPage);

      window.scrollTo(0, 0);
    },

    inseratBesetzt(jobId: any) {
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/inserat-besetzt',
        {
          'jobId': jobId
        }
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          // window.location.href = `${SITEURL}`;

          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Inserat wurde als besetzt markiert.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.dispatchEvent( new CustomEvent( 'reload' ) );

          SERVICES.overlays.hide();
        }
      } )
      .catch( error => {
        this.isLoading = false;
        this.errorArray = [];
        this.errorArray.push(this.t('Inserat konnte nicht als besetzt markiert werden.'));
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    },

    inseratDuplizieren(jobId: any) {
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/inserat-duplizieren',
        {
          'jobId': jobId
        }
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          window.location.href = `${SITEURL}arbeitgeber/job-bearbeiten?jobId=${response.data.id}`;

          // const flash = new CustomEvent( 'flash', {
          //   detail: {
          //     text: this.t('Inserat wurde dupliziert.'),
          //     class: 'success'
          //   }
          // } );
          // window.dispatchEvent( flash );

          // window.dispatchEvent( new CustomEvent( 'reload' ) );

          // SERVICES.overlays.hide();
        }
      } )
      .catch( error => {
        this.isLoading = false;
        this.errorArray = [];
        this.errorArray.push(this.t('Inserat konnte nicht dupliziert werden.'));
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    },

    inseratVeröffentlichen(jobId: any) {
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/inserat-veroeffentlichen',
        {
          'jobId': jobId
        }
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          // window.location.href = `${SITEURL}`;

          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Inserat wurde veröffentlicht.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.dispatchEvent( new CustomEvent( 'reload' ) );

          SERVICES.overlays.hide();
        }
      } )
      .catch( error => {
        this.isLoading = false;
        this.errorArray = [];
        this.errorArray.push(this.t('Inserat konnte nicht veröffentlicht werden.'));
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    },

    inseratNeuAuflisten(jobId: any) {
      SERVICES.bestaetigen.overlay(this.t('Inserat neu auflisten'), this.t('Den Job erneut zu listen, wird Ihnen ein Inserat vom Kontingent abziehen'), this.t('Neu auflisten'), () => {
        SERVICES.loading.startLoading();
        axios.post(
          SITEURL + 'actions/infajobs/jobs/inserat-neu-auflisten',
          {
            'jobId': jobId
          }
        )
        .then( response => {
          console.log( response );
          if ( response.status == 200 ) {
            this.isLoading = false;
            // window.location.href = `${SITEURL}`;

            const flash = new CustomEvent( 'flash', {
              detail: {
                text: this.t('Inserat wurde veröffentlicht.'),
                class: 'success'
              }
            } );
            window.dispatchEvent( flash );

            window.dispatchEvent( new CustomEvent( 'reload' ) );

            SERVICES.overlays.hide();
          }
        } )
        .catch( error => {
          this.isLoading = false;
          this.errorArray = [];
          this.errorArray.push(this.t('Inserat konnte nicht veröffentlicht werden.'));
        } )
        .finally(() => {
          SERVICES.loading.stopLoading();
        });
      });
    },

    inseratLöschen(jobId: any) {
      SERVICES.bestaetigen.overlay(this.t('Inserat löschen'), this.t('Bist du sicher, dass du das Inserat löschen willst?'), this.t('Löschen'), () => {
        SERVICES.loading.startLoading();
        axios.post(
          SITEURL + 'actions/infajobs/jobs/inserat-loeschen',
          {
            'jobId': jobId
          }
        )
        .then( response => {
          console.log( response );
          if ( response.status == 200 ) {

            const flash = new CustomEvent( 'flash', {
              detail: {
                text: this.t('Inserat wurde gelöscht.'),
                class: 'success'
              }
            } );
            window.dispatchEvent( flash );

            window.dispatchEvent( new CustomEvent( 'reload' ) );

            SERVICES.overlays.hide();
          }
        } )
        .catch( error => {
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Inserat konnte nicht gelöscht werden.'),
              class: 'error'
            }
          } );
          window.dispatchEvent( flash );

          window.dispatchEvent( new CustomEvent( 'reload' ) );
        } )
        .finally(() => {
          SERVICES.loading.stopLoading();
        });
      });
    },


    inseratBearbeiten() {

    },

    randomBewerber(job: any) {
      const shuffled = [...job.bewerbungen].sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 3);
    },

    formatDate(dateString) {
      if(dateString != '-') {
        let split = dateString.split(" ");
        let date = split[0];

        let dateParts = date.split("-")
        
        return [
          this.padTo2Digits(dateParts[2]),
          this.padTo2Digits(dateParts[1]),
          dateParts[0],
        ].join('.');
      }
      else {
        return '-';
      }
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
