import { VBaseService, VServices } from '@libTs/vue-base';

export class FlashService extends VBaseService {
  public flashText = '';
  public class = '';
  public timeOut: NodeJS.Timeout = setTimeout( () => { } );

  public setFlash( str: string, flashClass = 'success', time = 4000 ): void {
    clearTimeout( this.timeOut );
    this.class = flashClass;
    this.flashText = str;

    this.timeOut = setTimeout( () => {
      this.flashText = '';
    }, time );
  }

  public registerFlash(): void {
    window.addEventListener( 'flash', ( e: any ) => {
      if(e.detail.time) {
        this.setFlash( e.detail.text, e.detail.class, e.detail.time );
      }
      else {
        this.setFlash( e.detail.text, e.detail.class );
      }
    } );
  }

  public removeFlash(): void {
    clearTimeout( this.timeOut );
    this.class = '';
    this.flashText = '';
  }
}