import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { OverlaysService } from './overlays.service';
import { request } from 'http';
import { StatusService } from './status.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  status: StatusService,
  loading: LoadingService
} );

export class MeetingService extends VBaseService {

  public form = {

  };

  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];

  public meeting = {};

  public reload = true;

  public ansehen(meeting: any) {
    this.meeting = meeting;

    SERVICES.overlays.show('overlayMeetingKandidat');
  }

  
  public ansehenArbeitgeber(meeting: any) {
    this.meeting = meeting;

    SERVICES.overlays.show('overlayMeetingArbeitgeber');
  }

  public terminVorschlagen() {
    SERVICES.status.bewerbungId = this.meeting.bewerbungId;
    SERVICES.status.setDefaultText('einladungVorstellungsgespraech');
    SERVICES.overlays.show('overlayEinladungVorstellungsgespraech');
  }

  public ablehnenEinblenden() {
    this.meeting.akzeptiert = 'abgelehnt';
    this.meeting.rueckmeldungsweg = 'nachricht';

    this.reload = false;
    this.reload = true;
  }

  public ablehnenAusblenden() {
    this.meeting.akzeptiert = '';
    this.meeting.rueckmeldungsweg = '';

    this.reload = false;
    this.reload = true;
  }

  public meetingAblehnen(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.meeting
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/meeting-ablehnen',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        // window.location.href = `${SITEURL}`;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Meeting wurde abgelehnt.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      this.errorArray = [];
      this.errorArray.push(this.t('Meeting konnte nicht abgelehnt werden.'));
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public meetingAnnehmen(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.meeting
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/meeting-annehmen',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        // window.location.href = `${SITEURL}`;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Meeting wurde angenommen.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      this.errorArray = [];
      this.errorArray.push(this.t('Meeting konnte nicht angenommen werden.'));
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  
  formatDate(dateString) {
    let split = dateString.split(" ");
    let date = split[0];

    let dateParts = date.split("-")
    
    return [
      this.padTo2Digits(dateParts[2]),
      this.padTo2Digits(dateParts[1]),
      dateParts[0],
    ].join('.');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}