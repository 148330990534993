import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class JobService extends VBaseService {

  public form = {};

  public isValid = true;
  public isLoading = false;

  public options = {
    styles:
    [
      {
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#f5f5f5'
          }
        ]
      },
      {
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#616161'
          }
        ]
      },
      {
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'color': '#f5f5f5'
          }
        ]
      },
      {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#bdbdbd'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#eeeeee'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#757575'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#e5e5e5'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9e9e9e'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#ffffff'
          }
        ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#757575'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#dadada'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#616161'
          }
        ]
      },
      {
        'featureType': 'road.local',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9e9e9e'
          }
        ]
      },
      {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#e5e5e5'
          }
        ]
      },
      {
        'featureType': 'transit.station',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#eeeeee'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#c9c9c9'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9e9e9e'
          }
        ]
      }
    ]
  };
  

  public addToMerkliste(jobId: any) {

    this.isLoading = true;

    const requestData = {
      'jobId': jobId
    };


    console.log( requestData );

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/add-to-merkliste',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Job wurde auf Merkliste gesetzt.'),
            class: 'success',
            time: 2000
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Job konnte nicht auf Merkliste gesetzt werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public removeFromMerkliste(jobId: any) {

    this.isLoading = true;

    const requestData = {
      'jobId': jobId
    };


    console.log( requestData );

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/remove-from-merkliste',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Job wurde von Merkliste entfernt'),
            class: 'success',
            time: 2000
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
      }
    } )
    .catch( error => {
      this.isLoading = false;
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Job konnte nicht von Merkliste entfernt werden'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public scrollToJobs() {
    const element = document.querySelector('#arbeitgeber-jobs');

    if(element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}