var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.images.length > 0
      ? _c(
          "div",
          { staticClass: "gallery" },
          _vm._l(_vm.images.slice(0, 5), function(image, loopIndex) {
            return _c("div", {
              key: loopIndex,
              staticClass: "bild",
              class: { "col-span-2 row-span-2": loopIndex == 0 },
              style: {
                "background-image": "url(" + image.url + ")"
              },
              on: {
                click: function($event) {
                  _vm.index = loopIndex
                  _vm.open = true
                }
              }
            })
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "flex justify-end mt-8" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              _vm.index = 0
              _vm.open = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.t("Alle Fotos anzeigen")))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.open,
            expression: "open"
          }
        ],
        staticClass: "lightbox"
      },
      [
        _c(
          "button",
          {
            staticClass: "close-button",
            on: {
              click: function($event) {
                _vm.open = false
              }
            }
          },
          [_c("i", { staticClass: "far fa-times" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "prev-button",
            on: {
              click: function($event) {
                return _vm.previousImage()
              }
            }
          },
          [_c("i", { staticClass: "far fa-chevron-left" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lightbox-container" }, [
          _c("div", {
            staticClass: "lightbox-bild",
            style: {
              "background-image": "url(" + _vm.images[_vm.index].url + ")"
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "next-button",
            on: {
              click: function($event) {
                return _vm.nextImage()
              }
            }
          },
          [_c("i", { staticClass: "far fa-chevron-right" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }