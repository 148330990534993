import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { OverlaysService } from './overlays.service';
import { request } from 'http';
import { filter } from 'vue/types/umd';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class SpringeralarmService extends VBaseService {

  public form = {
    pensumVon: '',
    pensumBis: '',
    springerEinsatzdauer: ''
  };

  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];


  public createSpringeralarm(e) {
    e.preventDefault();

    this.isLoading = true;

    const flash = new CustomEvent( 'flash', {
      detail: {
        text: this.t('Wir suchen nach passenden Springerinnen und Springer, bitte habe einen Moment Geduld.'),
        class: 'success'
      }
    } );
    window.dispatchEvent( flash );

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/springeralarm',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        window.location.href = `${SITEURL}cart`;

        // const flash = new CustomEvent( 'flash', {
        //   detail: {
        //     text: this.t('Springer-Alarm wurde erstellt.'),
        //     class: 'success'
        //   }
        // } );
        // window.dispatchEvent( flash );

        // window.dispatchEvent( new CustomEvent( 'reload' ) );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      const message = error.response && error.response.data && error.response.data.frontendMessage ?
      error.response.data.frontendMessage : this.t('Springeralarm konnte nicht erstellt werden.');

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: message,
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }


  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}