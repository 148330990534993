var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "job" }, [
    _c(
      "div",
      {
        staticClass:
          "flex gap-4 items-center s:pb-4 s:border-b s:border-color10 s:w-full"
      },
      [
        _vm.job.arbeitgeberLogo
          ? _c("div", {
              staticClass:
                "bg-color6 h-24 w-24 bg-cover bg-center rounded-lg s:h-20 s:w-20",
              style: {
                "background-image": "url(" + _vm.job.arbeitgeberLogo + ")"
              }
            })
          : _c(
              "div",
              {
                staticClass:
                  "bg-color5 h-24 w-24 bg-cover bg-center rounded-lg relative"
              },
              [
                _c("span", { staticClass: "text-center center" }, [
                  _vm._v(_vm._s(_vm.job.arbeitgeberName))
                ])
              ]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-col" }, [
          _c("div", { staticClass: "text-f2 font-bold" }, [
            _vm._v(_vm._s(_vm.job.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex gap-4 items-center" }, [
            _c("div", [_vm._v(_vm._s(_vm.job.funktion))]),
            _vm._v(" "),
            _vm.job.kanton
              ? _c("div", [
                  _c("i", { staticClass: "fas fa-map-marker-alt text-color5" }),
                  _vm._v("\n          " + _vm._s(_vm.job.kanton) + "\n        ")
                ])
              : _vm._e()
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex gap-4 items-center s:justify-end s:w-full" },
      [
        !_vm.isArbeitgeber && !_vm.job.beworben
          ? _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.bewerben(_vm.job.id)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.t("Jetzt bewerben")))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.job.beworben
          ? _c(
              "button",
              {
                staticClass: "btn minL:hover:bg-color1",
                attrs: { disabled: "" }
              },
              [_vm._v(_vm._s(_vm.t("Bereits beworben")))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoggedIn && !_vm.isArbeitgeber
          ? _c(
              "button",
              {
                staticClass: "btn-green btn-round flex items-center",
                class: {
                  isNotFavorit: !_vm.job.favorit,
                  isFavorit: _vm.job.favorit
                },
                on: {
                  click: function($event) {
                    return _vm.merken(_vm.job)
                  }
                }
              },
              [
                _c("i", { staticClass: "fa-heart text-f2 far hoverHeart" }),
                _vm._v(" "),
                _c("i", { staticClass: "fa-heart text-f2 fas normalHeart" })
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }