






import Vue from "vue";

export default Vue.extend({
  name: 'ScrollTop',
  data() {
    return {
      isShown: false,
      scrollTimer: null
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll (e: any) {
      if(this.scrollTimer != null) {
        clearTimeout(this.scrollTimer);
      }
      this.isShown = false;

      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0;

      this.scrollTimer = setTimeout(() => {
        this.isShown = top > window.innerHeight / 2;
      }, 700);
    },
    toTop () {
      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 100); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
    }
  }
});
