var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "border-b border-color5 pb-4 mb-4" }, [
      _c("div", { staticClass: "text-f2 font-bold" }, [
        _vm._v(
          _vm._s(_vm.shownBewerbungen.length) +
            " " +
            _vm._s(_vm.t("zuletzt veränderte Bewerbungen"))
        )
      ])
    ]),
    _vm._v(" "),
    _vm.shownBewerbungen.length > 0
      ? _c(
          "div",
          {
            staticClass: "flex flex-col gap-4 border-b border-color5 pb-4 mb-4"
          },
          _vm._l(_vm.shownBewerbungen, function(bewerbung, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "shadow rounded-lg p-4 flex gap-4 justify-between s:flex-col"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex gap-4 s:flex-col s:pb-4 s:border-color10 s:border-b"
                  },
                  [
                    _c("div", {
                      staticClass:
                        "h-16 w-16 rounded-lg bg-cover bg-center shrink-0",
                      style: {
                        "background-image":
                          "url(" + bewerbung.kandidat.profilbild + ")"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c("div", { staticClass: "text-f2 font-bold" }, [
                        _vm._v(_vm._s(bewerbung.kandidat.name))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex gap-x-4 gap-y-0 flex-wrap" },
                        [
                          _c("div", {}, [
                            _c("i", {
                              staticClass:
                                "fa-sharp fa-solid fa-location-dot text-color5"
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(bewerbung.kandidat.kanton) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _c("span", { staticClass: "text-color8" }, [
                              _vm._v(_vm._s(_vm.t("Qualifikation:")))
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(bewerbung.kandidat.qualifikation) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _c("span", { staticClass: "text-color8" }, [
                              _vm._v(_vm._s(_vm.t("Kündigungsfrist:")))
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(bewerbung.kandidat.kuendigungsfrist) +
                                "\n            "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("span", { staticClass: "text-color8" }, [
                          _vm._v(_vm._s(_vm.t("Eingang Bewerbung:")))
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(bewerbung.bewerbungsdatum) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "flex gap-2 flex-wrap" },
                          _vm._l(bewerbung.status, function(
                            status,
                            indexStatus
                          ) {
                            return _c(
                              "div",
                              {
                                key: indexStatus,
                                staticClass: "status",
                                class: status
                                  .replaceAll("'", "")
                                  .replaceAll(":", "")
                                  .replaceAll(" ", "-")
                                  .toLowerCase()
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(status) +
                                    "\n              "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", {}, [
                  _c("div", { staticClass: "flex s:justify-end" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                        on: {
                          click: function($event) {
                            return _vm.toggleMenu(index)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far center",
                          class: {
                            "fa-ellipsis-v": _vm.menuIndex != index,
                            "fa-times": _vm.menuIndex == index
                          }
                        }),
                        _vm._v(" "),
                        _vm.menuIndex == index
                          ? _c(
                              "div",
                              { staticClass: "circle-dropdown text-color1" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "minL:hover:text-color5 cursor-pointer text-left",
                                    on: {
                                      click: function($event) {
                                        _vm.erhaltBestätigen(bewerbung.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("Erhalt bestätigen")) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "delete-link",
                                    on: {
                                      click: function($event) {
                                        return _vm.bewerbungAblehnen(
                                          bewerbung.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("Bewerbung ablehnen")) +
                                        "\n                "
                                    ),
                                    _c("i", { staticClass: "far fa-times" })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }