import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class JobausschreibungService extends VBaseService {

  public form = {};
  public showForm = false;


  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];

  public zoom = 9;
  public updateCoords = true;
  public reload = true;

  public gateway = 3;
  public agb = false;

  public button = '';

  public customToolbar = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];

  public editorSettings = {
    formats: ['bold', 'italic', 'link', 'strike', 'underline', 'script', 'code', 'blockquote', 'header', 'indent', 'list', 'code-block']
  };

  constructor() {
    super()

    this.setAddress();
  }

  public setAddress(): void {
    this.isLoading = true;

    axios.get(SITEURL + 'actions/infajobs/user/get-profile')
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.form.adresse = response.data.adresse;
        this.form.adresszusatz = response.data.adresszusatz;
        this.form.plzUndOrt = response.data.plzUndOrt;
        this.form.kanton = response.data.kanton;
        this.form.kartenStandort = response.data.kartenStandort;
        this.form.breitengrad = response.data.breitengrad;
        this.form.laengengrad = response.data.laengengrad;
      }
    } )
    .catch( error => {
      this.isLoading = false;
    } );
  }

  addToCart(purchasableId) {
    SERVICES.loading.startLoading();
    axios.post(
      `${SITEURL}actions/infajobs/shop/add-to-cart`,
      {
        productId: purchasableId,
      }
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        window.location.href = `${SITEURL}cart`;
      }
    } )
    .catch( error => {
      this.isLoading = false;
      this.errorArray = [];
      this.errorArray.push(this.t('Produkt konnte nicht zu Warenkorb hinzugefügt werden.'));
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }
  
  public loadJob(jobId): void {
    if(!this.form.jobId) {
      this.isLoading = true;

      console.log('jobId', jobId);
  
      axios.get(SITEURL + 'actions/infajobs/jobs/get-job?jobId=' + jobId)
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.form = response.data;
        }
      } )
      .catch( error => {
        this.isLoading = false;
      } );
    }
  }

  public setId() {
    window.scrollTo(0, 0);
    this.showForm = true;
  }

  public createJob(e) {
    e.preventDefault();

    this.isLoading = true;

    if(this.button == 'veröffentlichen') {
      const requestData = {
        ...this.form
      };
  
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/create',
        requestData
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Inserat wurde ausgeschrieben.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.location.href = `${SITEURL}arbeitgeber/job-ausschreiben`;
        }
      } )
      .catch( error => {
        this.isLoading = false;
        
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Inserat konnte nicht ausgeschrieben werden.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    }

    if(this.button == 'entwurf') {
      const requestData = {
        ...this.form,
        entwurf: true
      };
  
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/create',
        requestData
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Entwurf wurde gespeichert.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          this.showForm = false;
          window.scrollTo(0, 0);
        }
      } )
      .catch( error => {
        this.isLoading = false;
        
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Entwurf konnte nicht gespeichert werden.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    }

    if(this.button == 'vorschau') {
      console.log(e);
      const requestData = {
        ...this.form,
        entwurf: true
      };
  
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/jobs/create',
        requestData
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          this.isLoading = false;
          
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Entwurf wurde gespeichert.'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          console.log(response.data);
          const url = SITEURL + 'job/entwurf?id=' + response.data.id;
          window.open(url, '_blank')?.focus();
        }
      } )
      .catch( error => {
        this.isLoading = false;
        
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Entwurf konnte nicht gespeichert werden.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    }

  }

  public editJob(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.form
    };


    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/edit',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Job wurde gespeichert.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        // window.location.href = `${SITEURL}arbeitgeber/meine-jobs`;

        this.showForm = false;
      }
    } )
    .catch( error => {
      this.isLoading = false;
      
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Job konnte nicht bearbeitet werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public changeAnsprechpersonBild(event: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();


      fr.onload = (e: any) => {
        console.log(fr.result);
        this.form.bildAnsprechperson = fr.result;
        this.reload = false;
        this.reload = true;
      }

      fr.readAsDataURL(file);
    }
  }

  public setCoordinates(e) {
    this.zoom = 11;
    this.form.breitengrad = e.geometry.location.lat();
    this.form.laengengrad = e.geometry.location.lng();

    this.form.kartenStandort = e.name + ', ' + e.formatted_address;
    this.updateCoords = false;
    this.updateCoords = true;
  }

  public agbError() {
    const flash = new CustomEvent( 'flash', {
      detail: {
        text: this.t("Bitte akzeptiere die AGB's"),
        class: 'error'
      }
    } );
    window.dispatchEvent( flash );
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}