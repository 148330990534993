import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class RegisterService extends VBaseService {

  public form = {
    type: 'kandidat',
    email: '',
    password: '',
    passwordRepeat: '',
  };


  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];

  public loginTab = true;

  
  constructor() {
    super()

    const url = new URL(window.location.href);
    if (url.searchParams.has('login')) {

      setTimeout(() => {
        SERVICES.overlays.show('overlayLogin');
      }, 500);
      
    }
  }

  
  public createUser(e) {
    e.preventDefault();

    this.isLoading = true;

    if(this.form.password == this.form.passwordRepeat) {
      const requestData = {
        ...this.form
      };
  
      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/user/register',
        requestData
      )
      .then( response => {
        console.log( response );
        if ( response.status == 200 ) {
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Vielen Dank für deine Registrierung. Bestätige bitte deine E-Mail Adresse mit dem Link, den wir dir gesendet haben.'),
              class: 'success',
              time: 6000
            }
          } );
          window.dispatchEvent( flash );
          this.loginTab = true;
        }
      } )
      .catch( error => {
        this.isLoading = false;

        const message = error.response && error.response.data && error.response.data.frontendMessage ?
        error.response.data.frontendMessage : this.t('Benutzer konnte nicht erstellt werden.');

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: message,
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
      
    } else {
      this.isLoading = false;

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Passwörter stimmen nicht überein.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    }


  }


  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}