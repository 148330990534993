import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { OverlaysService } from './overlays.service';
import { request } from 'http';
import { BestaetigenService } from './bestaetigen.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  bestaetigen: BestaetigenService,
  loading: LoadingService
} );

export class ProfileService extends VBaseService {

  public form = {};
  public bewerbungsForm = {};

  public isValid = true;
  public isLoading = false;

  public menuIndex = null;

  public zoom = 9;

  public editedNetzwerkId = null;

  public newLanguage = {
    name: '',
    niveau: ''
  };

  constructor() {
    super()

    this.setProfile();
  }

  public setProfile(): void {

    axios.get(SITEURL + 'actions/infajobs/user/get-profile')
    .then( response => {
      if ( response.status == 200 ) {
        this.form = response.data;
      }
    } )
    .catch( error => {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Profil konnte nicht geladen werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } );

    axios.get(SITEURL + 'actions/infajobs/user/get-bewerbungsunterlagen')
    .then( response => {
      if ( response.status == 200 ) {
        this.bewerbungsForm = response.data;
      }
    } )
    .catch( error => {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerbungsunterlagen konnten nicht geladen werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } );
  }

  public updateProfile(e) {
    e.preventDefault();

    if(this.form.istSpringer && this.form.springerKantone.length == 0) {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bitte fülle das Feld Kantone aus.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );

      return false;
    }

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/user/update-profile',
      requestData
    )
    .then( response => {
      if ( response.status == 200 ) {
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Profil gespeichert.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.location.replace(SITEURL + 'arbeitgeber/dashboard');
      }
    } )
    .catch( error => {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Profil konnte nicht gespeichert werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    })
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public updateBewerbungsunterlagen() {

    if(this.bewerbungsForm.lebenslauf && this.bewerbungsForm.lebenslauf.length > 0) {

      const requestData = {
        ...this.bewerbungsForm
      };

      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/user/update-bewerbungsunterlagen',
        requestData
      )
      .then( response => {
        if ( response.status == 200 ) {
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Bewerbungsunterlagen gespeichert'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );
        }
      } )
      .catch( error => {
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerbungsunterlagen konnten nicht gespeichert werden'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });      
    }
    else {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bitte laden Sie einen Lebenslauf hoch'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    }
  }

  public changePassword(e) {
    e.preventDefault();

    if(this.form.password == this.form.passwordRepeat) {
      const requestData = {
        ...this.form
      };

      SERVICES.loading.startLoading();

      axios.post(
        SITEURL + 'actions/infajobs/user/change-password',
        requestData
      )
      .then( response => {
        if ( response.status == 200 ) {
          this.isLoading = false;

          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Passwort wurde geändert'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.location.replace(SITEURL + 'arbeitgeber/dashboard');
        }
      } )
      .catch( error => {
        this.isLoading = false;

        const message = error.response && error.response.data && error.response.data.frontendMessage ?
          error.response.data.frontendMessage : this.t('Passwort konnte nicht gespeichert werden.');

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: message,
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    } else {
      this.isLoading = false;

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Passwörter stimmen nicht überein.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    }
  }

  public deleteProfile(e) {
    e.preventDefault();

    SERVICES.bestaetigen.overlay(this.t('Profil löschen'), this.t('Bist du sicher, dass du dein Profil löschen willst?'), this.t('Löschen'), () => {
    
      const requestData = {
        ...this.form
      };

      SERVICES.loading.startLoading();
      axios.post(
        SITEURL + 'actions/infajobs/user/delete-profile',
        requestData
      )
      .then( response => {
        if ( response.status == 200 ) {
          this.isLoading = false;

          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Profil wurde gelöscht'),
              class: 'success'
            }
          } );
          window.dispatchEvent( flash );

          window.location.replace(SITEURL);
        }
      } )
      .catch( error => {

        const message = error.response && error.response.data && error.response.data.frontendMessage ?
          error.response.data.frontendMessage : this.t('Profil konnte nicht gelöscht werden.');

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: message,
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      } )
      .finally(() => {
        SERVICES.loading.stopLoading();
      });
    });
  }


  public changeProfilbild(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.profilbild = fr.result;
      }

      fr.readAsDataURL(file);
    }
  }

  public changeLogo(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.logo = fr.result;
      }

      fr.readAsDataURL(file);
    }
  }

  public changeTitelbild(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.titelbild = fr.result;
      }

      fr.readAsDataURL(file);
    }
  }


  public addToGalerie(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.galerie.push(
          {
            'url': fr.result,
            'name': file.name
          }
        );
      }

      fr.readAsDataURL(file);
    }
  }

  public removeFromGalerie(image: string) {
    const index = this.form.galerie.indexOf(image);
    if (index !== -1) {
      this.form.galerie.splice(index, 1);
    }
  }

  public addToLebenslauf(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.lebenslauf.push(
          {
            'url': fr.result,
            'name': file.name,
            'date': new Date().toLocaleDateString()
          }
        );
      }

      fr.readAsDataURL(file);
    }
  }

  public removeFromLebenslauf(image: string) {
    const index = this.bewerbungsForm.lebenslauf.map(object => object.url).indexOf(image.url);
    if (index !== -1) {
      this.bewerbungsForm.lebenslauf.splice(index, 1);
    }
  }

  public addToDiplome(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.diplome.push(
          {
            'url': fr.result,
            'name': file.name,
            'date': new Date().toLocaleDateString()
          }
        );
      }

      fr.readAsDataURL(file);
    }
  }

  public removeFromDiplome(image: string) {
    const index = this.bewerbungsForm.diplome.map(object => object.url).indexOf(image.url);
    if (index !== -1) {
      this.bewerbungsForm.diplome.splice(index, 1);
    }
  }

  public addToArbeitszeugnisse(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      fr.onload = function() {
        form.arbeitszeugnisse.push(
          {
            'url': fr.result,
            'name': file.name,
            'date': new Date().toLocaleDateString()
          }
        );
      }

      fr.readAsDataURL(file);
    }
  }

  public removeFromArbeitszeugnisse(image: string) {
    const index = this.bewerbungsForm.arbeitszeugnisse.map(object => object.url).indexOf(image.url);
    if (index !== -1) {
      this.bewerbungsForm.arbeitszeugnisse.splice(index, 1);
    }
  }

  public logout() {
    axios.get( SITEURL + 'actions/infajobs/user/logout' )
    .then( response => {
      if (response.data) {
        window.location.replace(SITEURL);
      }
    }).catch( error => {
      console.log(error);
    });
  }

  public setMenuIndex(index) {
    if(this.menuIndex == index) {
      this.menuIndex = null;
    }
    else {
      this.menuIndex = index;
    }
  }

  public previewLink(base64) {
    const win = window.open();
    win.document.write('<iframe src="' + base64  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  public setCoordinates(e) {
    this.zoom = 11;
    this.form.breitengrad = e.geometry.location.lat();
    this.form.laengengrad = e.geometry.location.lng();
    this.form.kartenStandort = e.name + ', ' + e.formatted_address;
  }

  public addNetzwerk(e): void {
    e.preventDefault();

    this.form.sozialeNetzwerke.push({
      'netzwerk': JSON.parse(e.target[0].value),
      'url': e.target[1].value
    });

    e.srcElement.reset();
    SERVICES.overlays.hide();
  }

  
  public saveNetzwerk(e): void {
    e.preventDefault();

    this.form.sozialeNetzwerke[this.editedNetzwerkId].netzwerk = JSON.parse(this.form.sozialeNetzwerke[this.editedNetzwerkId].netzwerk);

    e.srcElement.reset();
    SERVICES.overlays.hide();

    this.editedNetzwerkId = null;
  }

  public removeNetzwerk(index): void {
    this.form.sozialeNetzwerke.splice(index, 1);
  }

  public editNetzwerk(index): void {
    this.editedNetzwerkId = index;
    this.form.sozialeNetzwerke[index].netzwerk = JSON.stringify(this.form.sozialeNetzwerke[index].netzwerk);

    SERVICES.overlays.show('overlayEditNetzwerk');
  }

  public addLanguage(): void {
    this.form.sprachen.push({
      sprachname: this.newLanguage.name,
      sprachniveau: JSON.parse(this.newLanguage.niveau),
    });

    this.newLanguage = {
      name: '',
      niveau: ''
    };
  }

  public removeSprache(index): void {
    this.form.sprachen.splice(index, 1);
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}