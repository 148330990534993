var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "bewerbungen" },
      _vm._l(_vm.shownBewerbungen, function(alarm, index) {
        return _c(
          "div",
          { key: index, staticClass: "border-b border-color5 pb-4" },
          [
            _c("div", { staticClass: "bewerbung border-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex gap-4 items-center s:flex-col s:items-start s:pb-4 s:border-color10 s:border-b"
                },
                [
                  _c("div", { staticClass: "job-alarm-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex flex-col gap-2" }, [
                    _c("div", { staticClass: "text-f2 font-bold" }, [
                      _vm._v(_vm._s("Springer-Alarm"))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex gap-x-4 flex-wrap gap-y-0" },
                      [
                        _c("div", [_vm._v(_vm._s(alarm.funktionen))]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(alarm.pensumVon) +
                              "% - " +
                              _vm._s(alarm.pensumBis) +
                              "%"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(alarm.einsatzdauer))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex gap-x-4 flex-wrap gap-y-0" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.t("Erstellt:")) +
                              "\n                "
                          ),
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.formatDate(alarm.datum)))
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex s:justify-end" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                    on: {
                      click: function($event) {
                        return _vm.toggleMenu(index)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "far center",
                      class: {
                        "fa-ellipsis-v": _vm.menuIndex != index,
                        "fa-times": _vm.menuIndex == index
                      }
                    }),
                    _vm._v(" "),
                    _vm.menuIndex == index
                      ? _c(
                          "div",
                          { staticClass: "circle-dropdown text-color1" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "delete-link",
                                on: {
                                  click: function($event) {
                                    return _vm.removeJobalarm(alarm.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.t("Löschen")) +
                                    "\n                "
                                ),
                                _c("i", { staticClass: "far fa-times" })
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(alarm.kandidaten, function(kandidat, kandidatIndex) {
              return _c(
                "div",
                {
                  key: kandidatIndex,
                  staticClass:
                    "shadow rounded-lg p-4 flex justify-between gap-4 s:flex-col"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex gap-4 s:flex-col s:items-start s:pb-4 s:border-color10 s:border-b"
                    },
                    [
                      _c("div", {}, [
                        kandidat.profilbild
                          ? _c("div", {
                              staticClass:
                                "bg-color6 h-24 w-24 bg-cover bg-center rounded-lg s:h-20 s:w-20",
                              style: {
                                "background-image":
                                  "url(" + kandidat.profilbild + ")"
                              }
                            })
                          : _c("div", {
                              staticClass: "bg-color6 h-24 w-24 rounded-lg"
                            })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex flex-col gap-2" }, [
                        _c("div", { staticClass: "text-f2 font-bold" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(kandidat.vorname) +
                              " " +
                              _vm._s(kandidat.nachname) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex gap-y-2 gap-x-4 flex-wrap" },
                          [
                            kandidat.plzUndOrt
                              ? _c("div", [
                                  _c("i", {
                                    staticClass:
                                      "fa-sharp fa-solid fa-location-dot text-color5"
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(kandidat.plzUndOrt) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            kandidat.qualifikation
                              ? _c("div", [
                                  _c("span", { staticClass: "text-color8" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.t("Qualifikation:")) +
                                        "\n                "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(kandidat.qualifikation) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex gap-y-2 gap-x-4 flex-wrap" },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-color5 minL:hover:text-color1 cursor-pointer",
                                attrs: { href: kandidat.url, target: "blank" }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-long-arrow-right"
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.t("Profil ansehen")) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-color5 minL:hover:text-color1 cursor-pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.downloadUnterlagen(kandidat)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-long-arrow-down"
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.t("Download Bewerbung")) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            })
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-6" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.overlays.show("overlaySpringeralarm")
            }
          }
        },
        [_vm._v(_vm._s(_vm.t("Neuen Springer-Alarm erstellen")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }