import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class BewerbungService extends VBaseService {

  public form = {};
  public ansehenBewerbung = {
    zusätzlicheDateien: {}
  };

  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];

  public bewerbungsschreibenUploaded = false;
  public reload = true;


  public createBewerbung(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.form
    };


    console.log( requestData );

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/bewerbung',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        SERVICES.overlays.hide();

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bewerbung wurde gesendet'),
            class: 'success',
            time: 2000
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        // window.location.href = `${SITEURL}`;
      }
    } )
    .catch( error => {
      this.isLoading = false;
      SERVICES.overlays.hide();

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerbung konnte nicht gesendet werden'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public bewerben(jobId: number) {
    axios.get(SITEURL + 'actions/infajobs/user/user-ready')
    .then(response => {
      if(response.data == 1) {  
        this.form.jobId = jobId;
        this.form.lebenslauf = true;
        this.form.arbeitszeugnisse = true;
        this.form.diplome = true;
        SERVICES.overlays.show('overlayBewerbung');
      }
      else if(response.data == 0) {
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bitte fülle zuerst dein Profil aus.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      }
      else if(response.data == -1) {
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Bitte lade zuerst einen Lebenslauf hoch.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      }
    })
    .catch( error => {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Benutzer konnte nicht überprüft werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    });
  }

  public ansehen(jobId: number) {
    axios.get(SITEURL + 'actions/infajobs/user/get-bewerbung?jobId=' + jobId)
    .then(response => {
      this.ansehenBewerbung = response.data;

      console.log('!!', this.ansehenBewerbung);
    })
    .catch( error => {
      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Bewerbung konnte nicht geladen werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    });

    SERVICES.overlays.show('overlayBewerbungAnsehen');
  }

  public changeBewerbungsschreiben(event: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      let thisRef = this;
      thisRef.bewerbungsschreibenUploaded = false;

      fr.onload = function() {
        thisRef.form.bewerbungsschreiben = {
          'url': fr.result,
          'name': file.name,
        };

        thisRef.bewerbungsschreibenUploaded = true;
      }

      fr.readAsDataURL(file);
    }
  }

  public addToZusätzlicheDateien(event: any, form: any) {
    const files = event.srcElement.files;

    if(files.length > 0) {
      const file = files[0];
      const fr = new FileReader();

      const thisRef = this;

      if(!form.zusätzlicheDateien) {
        form.zusätzlicheDateien = [];
      }

      fr.onload = function() {
        form.zusätzlicheDateien.push(
          {
            'url': fr.result,
            'name': file.name,
            'date': new Date().toLocaleDateString()
          }
        );

        thisRef.reload = false;
        thisRef.reload = true;

      }

      fr.readAsDataURL(file);
    }
  }

  public removeFromZusätzlicheDateien(image: string) {
    const index = this.ansehenBewerbung.zusätzlicheDateien.map(object => object.url).indexOf(image.url);
    if (index !== -1) {
      this.ansehenBewerbung.zusätzlicheDateien.splice(index, 1);

      this.reload = false;
      this.reload = true;
    }
  }

  public uploadZusatzDateien(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.ansehenBewerbung
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/upload-zusatz-dateien',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;

        SERVICES.overlays.hide();

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Zusätzliche Dateien wurden hinaufgeladen.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );
        // window.location.href = `${SITEURL}`;
      }
    } )
    .catch( error => {
      this.isLoading = false;
      SERVICES.overlays.hide();

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Zusätzliche Dateien konnten nicht hinaufgeladen werden.'),
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  downloadBewerbung(kandidatId: any, kandidatName: any) {
    axios
      .get(
        SITEURL +
          'actions/infajobs/jobs/get-bewerbung-zip-by-kandidat?kandidatId=' +
          kandidatId
      )
      .then((response) => {
        const a = document.createElement('a'); //Create <a>
        a.href = 'data:application/zip;base64,' + response.data; //Image Base64 Goes here
        a.download =
          'Bewerbungsunterlagen-' +
          kandidatName +
          '.zip'; //File name Here
        a.click(); //Downloaded file
      })
      .catch((error) => {
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Es konnten keine Bewerbungsunterlagen gefunden werden.'),
            class: 'error'
          }
        } );
        window.dispatchEvent( flash );
      });
  }

  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}