var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-search" }, [
    _c("div", { staticClass: "grid grid-cols-2 s:grid-cols-1 grow" }, [
      _c(
        "div",
        {
          staticClass: "kantone relative px-6 border-r border-color8 s:hidden",
          on: {
            mouseover: function($event) {
              _vm.hoverKantone = true
            },
            mouseleave: function($event) {
              _vm.hoverKantone = false
            }
          }
        },
        [
          _c("div", { staticClass: "dropdown-title overflow-hidden" }, [
            _c("i", { staticClass: "fas fa-map-marker-alt text-color5" }),
            _vm._v(" "),
            _vm.selectedKantone.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.selectedKantone, function(kanton, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "selected-kanton",
                        on: {
                          click: function($event) {
                            return _vm.toggleKanton(kanton)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(kanton)),
                        _vm.selectedKantone.length > 0 &&
                        index + 1 < _vm.selectedKantone.length
                          ? _c("span", [_vm._v(", ")])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _c("div", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.t("Kanton")) + "\n        "
                  )
                ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "search-dropdown",
              class: { hidden: _vm.hoverKantone == false }
            },
            _vm._l(_vm.kantoneData, function(kanton, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "kanton minL:hover:text-color5 cursor-pointer",
                  class: {
                    "text-color5": _vm.selectedKantone.includes(kanton)
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleKanton(kanton)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(kanton) + "\n        ")]
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "jobarten relative px-6",
          on: {
            mouseover: function($event) {
              _vm.hoverJobarten = true
            },
            mouseleave: function($event) {
              _vm.hoverJobarten = false
            }
          }
        },
        [
          _c("div", { staticClass: "dropdown-title overflow-hidden" }, [
            _c("i", { staticClass: "far fa-search text-color5" }),
            _vm._v(" "),
            _vm.selectedJobarten.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.selectedJobarten, function(jobart, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "selected-jobart",
                        on: {
                          click: function($event) {
                            return _vm.toggleJobart(jobart)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(jobart)),
                        _vm.selectedJobarten.length > 0 &&
                        index + 1 < _vm.selectedJobarten.length
                          ? _c("span", [_vm._v(", ")])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _c("div", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.t("Job suchen")) + "\n        "
                  )
                ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "search-dropdown",
              class: { hidden: _vm.hoverJobarten == false }
            },
            _vm._l(_vm.jobartenData, function(jobart, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "jobart minL:hover:text-color5 cursor-pointer",
                  class: {
                    "text-color5": _vm.selectedJobarten.includes(jobart)
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleJobart(jobart)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(jobart) + "\n        ")]
              )
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn grow-0 shrink-0",
        on: {
          click: function($event) {
            return _vm.redirectToSearch()
          }
        }
      },
      [_vm._v(_vm._s(_vm.t("Jobs finden")))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }