var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bewerbungen-wrapper" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-center s:grid s:grid-cols-1 s:gap-4"
      },
      [
        _c("div", {}, [
          _c("div", { staticClass: "relative" }, [
            _c("i", { staticClass: "far fa-search search-input-icon" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              staticClass: "search-input",
              attrs: {
                type: "search",
                placeholder: _vm.t("In Vorstellungsgesprächen suchen"),
                required: ""
              },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  function($event) {
                    return _vm.changePage()
                  }
                ]
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex items-center gap-4" }, [
          _c(
            "label",
            {
              staticClass: "pt-2 no-padding whitespace-nowrap",
              attrs: { for: "order" }
            },
            [_vm._v(_vm._s(_vm.t("Sortieren nach:")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrapper order-select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order,
                    expression: "order"
                  }
                ],
                attrs: { name: "order", id: "order" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.order = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.changePage()
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v(_vm._s(_vm.t("Neuste")))
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v(_vm._s(_vm.t("Älteste")))
                ])
              ]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bewerbungen" },
      _vm._l(_vm.shownBewerbungen, function(bewerbung, index) {
        return _c("div", { key: index, staticClass: "bewerbung" }, [
          _c(
            "div",
            {
              staticClass:
                "flex gap-4 items-center s:flex-col s:items-start s:pb-4 s:border-color10 s:border-b"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "bg-color5 h-24 w-24 bg-cover bg-center rounded-lg relative flex flex-col justify-between items-center s:h-20 s:w-20"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-color1 text-f1 h-full w-full relative"
                    },
                    [
                      _c("div", { staticClass: "center" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.getDay(
                                bewerbung.nachrichten[
                                  bewerbung.nachrichten.length - 1
                                ].datum
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-color10 w-full text-center rounded-b-lg p-2 s:text-f4 s:p-1"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.getMonthShort(
                              bewerbung.nachrichten[
                                bewerbung.nachrichten.length - 1
                              ].datum
                            )
                          ) +
                          "\n             - \n            " +
                          _vm._s(
                            _vm.getYear(
                              bewerbung.nachrichten[
                                bewerbung.nachrichten.length - 1
                              ].datum
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "text-f2 font-bold" }, [
                  _vm._v(
                    _vm._s(bewerbung.job) +
                      " - " +
                      _vm._s(_vm.t("Vorstellungsgespräch"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex gap-x-4 mb-2 flex-wrap" }, [
                  _c("div", [
                    _c("span", { staticClass: "text-color8" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.t("Gespräch mit:")) +
                          "\n              "
                      )
                    ]),
                    _vm._v(
                      " \n              " +
                        _vm._s(bewerbung.arbeitgeber) +
                        "\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex gap-x-4 flex-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "status",
                      class: bewerbung.status
                        .replaceAll("'", "")
                        .replaceAll(":", "")
                        .replaceAll(" ", "-")
                        .toLowerCase()
                    },
                    [_vm._v(_vm._s(_vm.t(bewerbung.status)))]
                  ),
                  _vm._v(" "),
                  bewerbung.nachrichten[bewerbung.nachrichten.length - 1].zeit
                    ? _c("div", [
                        _c("i", { staticClass: "far fa-clock text-color5" }),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              bewerbung.nachrichten[
                                bewerbung.nachrichten.length - 1
                              ].zeit
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  bewerbung.nachrichten[bewerbung.nachrichten.length - 1].ort
                    ? _c("div", [
                        _c("i", {
                          staticClass:
                            "fa-sharp fa-solid fa-location-dot text-color5"
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              bewerbung.nachrichten[
                                bewerbung.nachrichten.length - 1
                              ].ort
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex s:justify-end" }, [
            _c(
              "button",
              {
                staticClass:
                  "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                on: {
                  click: function($event) {
                    return _vm.toggleMenu(index)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "far center",
                  class: {
                    "fa-ellipsis-v": _vm.menuIndex != index,
                    "fa-times": _vm.menuIndex == index
                  }
                }),
                _vm._v(" "),
                _vm.menuIndex == index
                  ? _c("div", { staticClass: "circle-dropdown text-color1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "minL:hover:text-color5 text-left",
                          on: {
                            click: function($event) {
                              return _vm.detailsAnsehen(bewerbung)
                            }
                          }
                        },
                        [
                          bewerbung.status == "Offen" &&
                          bewerbung.nachrichten[
                            bewerbung.nachrichten.length - 1
                          ].akzeptiert == null
                            ? _c("span", [_vm._v(_vm._s(_vm.t("Beantworten")))])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.t("Details anschauen")))
                              ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pages" }, [
      _c(
        "div",
        { staticClass: "page-switcher" },
        [
          _vm.getPage() > 1
            ? _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      _vm.changePage(_vm.getPage() - 1)
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-arrow-left" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(3, function(index) {
            return [
              index + _vm.getPage() - 2 > 0 &&
              index + _vm.getPage() - 2 <=
                Math.ceil(_vm.getNumberOfProducts() / _vm.getProductsPerPage())
                ? _c(
                    "button",
                    {
                      key: index,
                      staticClass: "page-numbers",
                      class: {
                        active: index + _vm.getPage() - 2 === _vm.getPage()
                      },
                      on: {
                        click: function($event) {
                          _vm.changePage(index + _vm.getPage() - 2)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(index + _vm.getPage() - 2) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _vm.getPage() <
          Math.ceil(_vm.getNumberOfProducts() / _vm.getProductsPerPage())
            ? _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      _vm.changePage(_vm.getPage() + 1)
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-arrow-right" })]
              )
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }