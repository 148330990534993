var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "arbeitgeber-nav relative",
      on: {
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c("div", { staticClass: "email flex gap-3 py-3 items-center" }, [
        _vm.userData.profilbild
          ? _c("div", {
              staticClass:
                "rounded-full h-10 w-10 bg-color10 relative bg-cover bg-center",
              style: {
                "background-image": "url(" + _vm.userData.profilbild + ")"
              }
            })
          : _c("i", { staticClass: "far fa-user-circle text-f2" }),
        _vm._v("\n    " + _vm._s(_vm.userData.name) + "\n    "),
        _c("i", {
          staticClass: "far ml-4",
          class: { "fa-chevron-down": !_vm.hover, "fa-chevron-up": _vm.hover }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "menu-dropdown absolute",
          class: { "h-0 py-0": _vm.hover == false }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col pb-4 pt-2 border-b border-color5 gap-4"
            },
            [
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/dashboard" } }, [
                _vm._v(_vm._s(_vm.t("Dashboard")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-col py-4 border-b border-color5 gap-4" },
            [
              _c(
                "a",
                { attrs: { href: _vm.siteUrl + "kandidat/bewerbungen" } },
                [_vm._v(_vm._s(_vm.t("Bewerbungen")))]
              ),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/merkliste" } }, [
                _vm._v(_vm._s(_vm.t("Merkliste")))
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/job-alarm" } }, [
                _vm._v(_vm._s(_vm.t("Job-Alarme")))
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/meetings" } }, [
                _vm._v(_vm._s(_vm.t("Vorstellungsgespräche")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-col py-4 border-b border-color5 gap-4" },
            [
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/profil" } }, [
                _vm._v(_vm._s(_vm.t("Profil bearbeiten")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: _vm.siteUrl + "kandidat/bewerbungsunterlagen" }
                },
                [_vm._v(_vm._s(_vm.t("Bewerbungsunterlagen")))]
              ),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.siteUrl + "kandidat/passwort" } }, [
                _vm._v(_vm._s(_vm.t("Passwort ändern")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: _vm.siteUrl + "kandidat/profil-loeschen" } },
                [_vm._v(_vm._s(_vm.t("Profil löschen")))]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex flex-col pt-4 pb-2 gap-4" }, [
            _c(
              "a",
              {
                staticClass: "logout",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.logout()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.t("Abmelden")))]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }