var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bewerbungen-wrapper" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-center s:grid s:grid-cols-1 s:gap-4"
      },
      [
        _c("div", {}, [
          _c("div", { staticClass: "relative" }, [
            _c("i", { staticClass: "far fa-search search-input-icon" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              staticClass: "search-input",
              attrs: {
                type: "search",
                placeholder: _vm.t("In Inseraten suchen"),
                required: ""
              },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  function($event) {
                    return _vm.changePage()
                  }
                ]
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex items-center gap-4" }, [
          _c(
            "label",
            {
              staticClass: "pt-2 no-padding whitespace-nowrap",
              attrs: { for: "order" }
            },
            [_vm._v(_vm._s(_vm.t("Sortieren nach:")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrapper order-select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order,
                    expression: "order"
                  }
                ],
                attrs: { name: "order", id: "order" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.order = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.changePage()
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v(_vm._s(_vm.t("Neuste")))
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v(_vm._s(_vm.t("Älteste")))
                ])
              ]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bewerbungen" },
      _vm._l(_vm.shownBewerbungen, function(job, index) {
        return _c(
          "div",
          { key: index, staticClass: "job-mit-bewerbung px-4" },
          [
            _c(
              "div",
              {
                staticClass: "flex flex-col gap-4 border-b border-color1 pb-4"
              },
              [
                _c("div", { staticClass: "flex justify-between s:flex-col" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col gap-2 s:border-color10 s:border-b s:pb-2 s:mb-4"
                    },
                    [
                      _c("div", { staticClass: "text-f2 font-bold" }, [
                        _vm._v(_vm._s(job.title))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex gap-x-4 mb-2 flex-wrap" },
                        [
                          _c("div", [_vm._v(_vm._s(job.funktion))]),
                          _vm._v(" "),
                          job.kanton
                            ? _c("div", [
                                _c("i", {
                                  staticClass:
                                    "fa-sharp fa-solid fa-location-dot text-color5"
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(job.kanton) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex gap-x-4 mb-2 flex-wrap" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "status",
                              class: job.status
                                .replaceAll("'", "")
                                .replaceAll(":", "")
                                .replaceAll(" ", "-")
                                .toLowerCase()
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.t(job.status)) +
                                  " "
                              ),
                              job.status == "Veröffentlicht" &&
                              job.veroeffentlichung
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.t("am")) +
                                        " " +
                                        _vm._s(
                                          _vm.formatDate(job.veroeffentlichung)
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          job.veroeffentlichung
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "text-color8 ml-2" },
                                  [_vm._v(_vm._s(_vm.t("Von:")))]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(job.veroeffentlichung)
                                    ) +
                                    "\n                "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-color8 ml-2" },
                                  [_vm._v(_vm._s(_vm.t("Bis:")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "text-color7": job.status == "Abgelaufen"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(job.deaktivierung))
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-start h-full grow-0 s:justify-end"
                    },
                    [
                      _c("div", { staticClass: "relative" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "rounded-full bg-color6 relative h-14 w-14 s:bg-color1 s:text-color2",
                            on: {
                              click: function($event) {
                                return _vm.toggleMenu(index)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "far center",
                              class: {
                                "fa-ellipsis-v": _vm.menuIndex != index,
                                "fa-times": _vm.menuIndex == index
                              }
                            }),
                            _vm._v(" "),
                            _vm.menuIndex == index
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "circle-dropdown text-color1"
                                  },
                                  [
                                    job.status == "Veröffentlicht"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left inline-block",
                                            attrs: { href: job.url }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.t("Inserat ansehen")
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    job.status == "Veröffentlicht"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left inline-block",
                                            attrs: {
                                              href:
                                                _vm.siteUrl +
                                                "arbeitgeber/bewerbungen?bewerbung=" +
                                                job.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.t("Bewerbungen")) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    job.status == "Veröffentlicht"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left",
                                            on: {
                                              click: function($event) {
                                                return _vm.inseratBesetzt(
                                                  job.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.t("Stelle besetzt")
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    job.status == "Veröffentlicht" ||
                                    job.status == "Abgelaufen" ||
                                    job.status == "Stelle besetzt"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left",
                                            on: {
                                              click: function($event) {
                                                return _vm.inseratDuplizieren(
                                                  job.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.t(
                                                    "Vorlage für neues Inserat"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    job.status == "Entwurf"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left",
                                            on: {
                                              click: function($event) {
                                                _vm.inseratVeröffentlichen(
                                                  job.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.t("Veröffentlichen")
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    job.status == "Veröffentlicht" ||
                                    job.status == "Entwurf"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "minL:hover:text-color5 cursor-pointer text-left inline-block",
                                            attrs: {
                                              href:
                                                _vm.siteUrl +
                                                "arbeitgeber/job-bearbeiten?jobId=" +
                                                job.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.t("Bearbeiten")) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "delete-link",
                                        on: {
                                          click: function($event) {
                                            _vm.inseratLöschen(job.id)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.t("Löschen")) +
                                            "\n                    "
                                        ),
                                        _c("i", { staticClass: "far fa-times" })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pt-4 flex gap-4 items-center" }, [
              _c(
                "div",
                { staticClass: "flex" },
                _vm._l(_vm.randomBewerber(job), function(
                  bewerber,
                  bewerberIndex
                ) {
                  return _c("div", {
                    key: bewerberIndex,
                    staticClass: "h-10 w-10 rounded-full bg-cover bg-center",
                    style: {
                      "background-image":
                        "url(" + bewerber.kandidat.profilbild + ")"
                    }
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("div", {}, [
                _c("span", { staticClass: "text-color8" }, [
                  _vm._v(_vm._s(_vm.t("Bewerber:innen: ")))
                ]),
                _vm._v(
                  "\n          " + _vm._s(job.bewerbungen.length) + "\n        "
                )
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pages" }, [
      _c(
        "div",
        { staticClass: "page-switcher" },
        [
          _vm.getPage() > 1
            ? _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      _vm.changePage(_vm.getPage() - 1)
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-arrow-left" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(3, function(index) {
            return [
              index + _vm.getPage() - 2 > 0 &&
              index + _vm.getPage() - 2 <=
                Math.ceil(_vm.getNumberOfProducts() / _vm.getProductsPerPage())
                ? _c(
                    "button",
                    {
                      key: index,
                      staticClass: "page-numbers",
                      class: {
                        active: index + _vm.getPage() - 2 === _vm.getPage()
                      },
                      on: {
                        click: function($event) {
                          _vm.changePage(index + _vm.getPage() - 2)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(index + _vm.getPage() - 2) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _vm.getPage() <
          Math.ceil(_vm.getNumberOfProducts() / _vm.getProductsPerPage())
            ? _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      _vm.changePage(_vm.getPage() + 1)
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-arrow-right" })]
              )
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }