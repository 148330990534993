













































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { OverlaysService } from '../services/overlays.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { BestaetigenService } from '../services/bestaetigen.service';
import { LoadingService } from '../services/loading.service';

const SERVICES = VServices({
  translation: TranslationService,
  overlays: OverlaysService,
  bestaetigen: BestaetigenService,
  loading: LoadingService
});

export default Vue.extend({
  name: 'kandidat-jobalarme',
  props: {
    data: String
  },
  data() {
    return {
      hover: false,
      bewerbungenData: [],
      shownBewerbungen: [],
      search: '',
      order: 0,
      currentPage: 1,
      pages: 1,
      recordsPerPage: 10,
      menuIndex: null,
      overlays: SERVICES.overlays
    };
  },
  mounted() {
    this.bewerbungenData = JSON.parse(this.data);
    let sortedArray = this.bewerbungenData.sort((a, b) => {
      if(a.datum < b.datum) {
        return -1;
      }
      if(a.datum > b.datum) {
        return 1;
      }
      return 0;
    });
    this.shownBewerbungen = sortedArray.slice(0, this.recordsPerPage);

    this.pages = Math.ceil(this.bewerbungenData.length / this.recordsPerPage);

    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-jobalarme')
      .then(response => {
        this.bewerbungenData = response.data;

        this.changePage();
      })
      .catch( error => {

      });
    });
  },
  methods: {
    removeJobalarm(joalarmId: any) {
      SERVICES.bestaetigen.overlay(this.t('Jobalarm löschen'), this.t('Bist du sicher, dass du den Jobalarm löschen willst?'), this.t('Löschen'), () => {

        const requestData = {
          'jobalarmId': joalarmId
        };

        SERVICES.loading.startLoading();
        axios.post(
          SITEURL + 'actions/infajobs/jobs/remove-jobalarm',
          requestData
        )
        .then( response => {
          console.log( response );
          if ( response.status == 200 ) {
            this.isLoading = false;

            const flash = new CustomEvent( 'flash', {
              detail: {
                text: this.t('Jobalarm wurde entfernt'),
                class: 'success'
              }
            } );
            window.dispatchEvent( flash );

            window.dispatchEvent( new CustomEvent( 'reload' ) );
          }
        } )
        .catch( error => {
          this.isLoading = false;
          const flash = new CustomEvent( 'flash', {
            detail: {
              text: this.t('Jobalarm konnte nicht entfernt werden'),
              class: 'error'
            }
          } );
          window.dispatchEvent( flash );
        } )
        .finally(() => {
          SERVICES.loading.stopLoading();
        });
      })
    },

    toggleMenu(index: any) {
      if(this.menuIndex == index) {
        this.menuIndex = null;
      }
      else {
        this.menuIndex = index;
      }
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.bewerbungenData.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage() {
      let sortedArray = this.bewerbungenData.sort((a: any, b: any) => {
        if(this.order == 0) {
          if(a.datum < b.datum) {
            return -1;
          }
          if(a.datum > b.datum) {
            return 1;
          }
        }
        else {
          if(a.datum < b.datum) {
            return 1;
          }
          if(a.datum > b.datum) {
            return -1;
          }
        }
        return 0;
      });

      let searchArray = sortedArray.filter((element: any) => {
        return (element.title.toUpperCase() + ' - ' + (element.stichwort ? element.stichwort.toUpperCase() : '')).includes(this.search.toUpperCase());
      });

      this.shownBewerbungen = searchArray;
    },

    formatDate(dateString) {
      let split = dateString.split(" ");
      let date = split[0];

      let dateParts = date.split("-")
      
      return [
        this.padTo2Digits(dateParts[2]),
        this.padTo2Digits(dateParts[1]),
        dateParts[0],
      ].join('.');
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
