








import Vue from "vue";
import { TranslationService } from '../services/translation.service';
import { VServices } from '@libTs/vue-base';


const SERVICES = VServices({
  translation: TranslationService,
});

export default Vue.extend({
  name: 'TestingBanner',
  data() {
    return {
      isShown: false,
      isShownMobile: false,
      scrollTimer: null
    }
  },
  created () {
    if(this.getCookie('testingbanner') == '1') {
      this.isShown = false;
    }
    else {
      this.isShown = true;
    }
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll (e: any) {
      if(this.scrollTimer != null) {
        clearTimeout(this.scrollTimer);
      }

      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0;

      this.scrollTimer = setTimeout(() => {
        this.isShownMobile = top > window.innerHeight / 2;
      }, 700);
    },
    setCookie(name: string, value: any, days: number) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    getCookie(name: string) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    removeBanner() {
      this.isShown = false;
      this.setCookie('testingbanner', '1', 1);
    },
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  }
});
