var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jobs-filter block-jobs" }, [
    _c(
      "div",
      { staticClass: "jobs" },
      _vm._l(_vm.shownJobs, function(job, index) {
        return _c("div", { key: index, staticClass: "job" }, [
          _c(
            "div",
            {
              staticClass: "flex gap-4 items-center w-full",
              class: { "border-b border-color8 pb-4": !_vm.isArbeitgeber }
            },
            [
              job.arbeitgeberLogo
                ? _c("div", {
                    staticClass:
                      "bg-color6 h-24 w-24 bg-cover bg-center rounded-lg s:h-20 s:w-20",
                    style: {
                      "background-image": "url(" + job.arbeitgeberLogo + ")"
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass:
                        "bg-color5 h-24 w-24 bg-cover bg-center rounded-lg relative"
                    },
                    [
                      _c("span", { staticClass: "text-center center" }, [
                        _vm._v(_vm._s(job.arbeitgeberName))
                      ])
                    ]
                  ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "flex flex-col", attrs: { href: job.url } },
                [
                  _c("div", { staticClass: "text-f2 font-bold" }, [
                    _vm._v("\n            " + _vm._s(job.name) + "\n          ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex gap-4" }, [
                    _c("div", [_vm._v(_vm._s(job.funktion))]),
                    _vm._v(" "),
                    job.kanton
                      ? _c("div", [
                          _c("i", {
                            staticClass:
                              "fa-sharp fa-solid fa-location-dot text-color5"
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(job.kanton) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-end gap-4 w-full" }, [
            !_vm.isArbeitgeber && !job.beworben
              ? _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function($event) {
                        return _vm.bewerben(job.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.t("Jetzt bewerben")))]
                )
              : _vm._e(),
            _vm._v(" "),
            job.beworben
              ? _c(
                  "button",
                  {
                    staticClass: "btn minL:hover:bg-color1",
                    attrs: { disabled: "" }
                  },
                  [_vm._v(_vm._s(_vm.t("Bereits beworben")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLoggedIn && !_vm.isArbeitgeber
              ? _c(
                  "button",
                  {
                    staticClass: "btn-green btn-round flex items-center",
                    class: {
                      isNotFavorit: !job.favorit,
                      isFavorit: job.favorit
                    },
                    on: {
                      click: function($event) {
                        return _vm.merken(job)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa-heart text-f2 far hoverHeart" }),
                    _vm._v(" "),
                    _c("i", { staticClass: "fa-heart text-f2 fas normalHeart" })
                  ]
                )
              : _vm._e()
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "py-12 text-center" }, [
      _c(
        "a",
        {
          staticClass: "btn inline-block",
          attrs: { href: _vm.siteUrl + "jobs-finden" }
        },
        [_vm._v(_vm._s(_vm.t("Alle Jobs anzeigen")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }