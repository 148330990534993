import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { OverlaysService } from './overlays.service';
import { request } from 'http';
import { filter } from 'vue/types/umd';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class JobalarmService extends VBaseService {

  public form = {
    haeufigkeit: '',
    kanton: '',
    funktion: '',
    anstellungsgrad: ''
  };

  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];


  public createJobalarm(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/jobalarm',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        // window.location.href = `${SITEURL}`;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Job-Alarm wurde erstellt.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      this.errorArray = [];
      this.errorArray.push(this.t('Jobalarm konnte nicht erstellt werden.'));
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }

  public createJobalarmFromSearch(e, filterArray: any) {
    e.preventDefault();

    this.form.kanton = filterArray.kanton;
    this.form.funktion = filterArray.funktion;
    this.form.anstellungsgrad = filterArray.art;

    this.isLoading = true;

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/jobs/jobalarm',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        // window.location.href = `${SITEURL}`;

        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Job-Alarm wurde erstellt.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        window.dispatchEvent( new CustomEvent( 'reload' ) );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;
      this.errorArray = [];
      this.errorArray.push(this.t('Jobalarm konnte nicht erstellt werden.'));
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });
  }


  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}