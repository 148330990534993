import { VDirective } from '@libTs/vue-base';

/**
 * v-loaded
 *
 * Automatically hides the element after 4 seconds
 */
export const LoadedDirective: VDirective = [
  'loaded',
  {
    bind( el: HTMLElement ): void {
      $(el).removeClass('hidden');
    },
  }
];
