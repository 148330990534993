
















import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';


const SERVICES = VServices({
  translation: TranslationService,
});

export default Vue.extend({
  name: 'notifications',
  props: {
    data: String
  },
  data() {
    return {
      hover: false,
      notificationsData: []
    };
  },
  mounted() {
    this.notificationsData = JSON.parse(this.data);
  },
  methods: {
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
