var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "col-span-12 flex justify-end" }, [
      _c("div", { staticClass: "py-8" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.t("Zeige")) +
            "\n      " +
            _vm._s((_vm.currentPage - 1) * _vm.recordsPerPage + 1) +
            " \n      " +
            _vm._s(_vm.t("bis")) +
            "\n      " +
            _vm._s(
              _vm.currentPage * _vm.recordsPerPage > _vm.allShownJobs.length
                ? _vm.allShownJobs.length
                : _vm.currentPage * _vm.recordsPerPage
            ) +
            " \n      " +
            _vm._s(_vm.t("von")) +
            " \n      " +
            _vm._s(_vm.allShownJobs.length) +
            "\n      " +
            _vm._s(_vm.t("Ergebnissen")) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dashboard-menu block menuBreak:hidden" }, [
      _c("div", { staticClass: "search-menu" }, [
        _c("div", { staticClass: "pb-4 border-color8 border-b" }, [
          _c("label", { attrs: { for: "stringSearch" } }, [
            _vm._v(_vm._s(_vm.t("Suche")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "relative" }, [
            _c("i", { staticClass: "far fa-search search-input-icon" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterArray.stringSearch,
                  expression: "filterArray.stringSearch"
                }
              ],
              staticClass: "search-input",
              attrs: {
                type: "search",
                id: "stringSearch",
                name: "stringSearch",
                placeholder: _vm.t("Suchbegriff eingeben"),
                required: ""
              },
              domProps: { value: _vm.filterArray.stringSearch },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.filterArray,
                      "stringSearch",
                      $event.target.value
                    )
                  },
                  function($event) {
                    return _vm.searchJobs()
                  }
                ]
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "kartenStandort" } }, [
              _vm._v(_vm._s(_vm.t("Ort")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "relative mb-4" },
              [
                _c("i", { staticClass: "far fa-search search-input-icon" }),
                _vm._v(" "),
                _c("vue-google-autocomplete", {
                  staticClass: "search-input",
                  attrs: {
                    id: "kartenStandort",
                    name: "kartenStandort",
                    placeholder: _vm.t("Ort oder PLZ"),
                    types: ["establishment"],
                    "component-restrictions": { country: ["ch", "li"] }
                  },
                  on: {
                    place_changed: function(e) {
                      return _vm.setStandortFilter(e)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v("\n\n        " + _vm._s(_vm.t("Radius")) + ": "),
            _c("span", [_vm._v(_vm._s(_vm.filterArray.radius) + " km")]),
            _vm._v(" "),
            _c("vue-slider", {
              on: {
                change: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.radius,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "radius", $$v)
                },
                expression: "filterArray.radius"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.t("Kanton")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.kantone,
                multiple: true,
                placeholder: _vm.t("Kanton auswählen")
              },
              on: {
                input: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.kanton,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "kanton", $$v)
                },
                expression: "filterArray.kanton"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.t("Funktion")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.funktionen,
                multiple: true,
                placeholder: _vm.t("Funktion auswählen")
              },
              on: {
                input: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.funktion,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "funktion", $$v)
                },
                expression: "filterArray.funktion"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.t("Anstellungsgrad")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.arten,
                multiple: true,
                placeholder: _vm.t("Anstellungsgrad auswählen")
              },
              on: {
                input: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.art,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "art", $$v)
                },
                expression: "filterArray.art"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.t("Berufserfahrung")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.erfahrungen,
                multiple: true,
                placeholder: _vm.t("Erfahrung auswählen")
              },
              on: {
                input: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.erfahrung,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "erfahrung", $$v)
                },
                expression: "filterArray.erfahrung"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-4 border-color8 border-b" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.t("Veröffentlichung")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.veroeffentlichungen,
                placeholder: _vm.t("Veröffentlichung auswählen"),
                "allow-empty": false,
                label: "name",
                "track-by": "hours"
              },
              on: {
                input: function($event) {
                  return _vm.searchJobs()
                }
              },
              model: {
                value: _vm.filterArray.veroeffentlichung,
                callback: function($$v) {
                  _vm.$set(_vm.filterArray, "veroeffentlichung", $$v)
                },
                expression: "filterArray.veroeffentlichung"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pt-4 text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  return _vm.showSearchedJobs()
                }
              }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.searchedJobs.length) +
                  "\n          "
              ),
              _vm.searchedJobs.length == 1
                ? _c("span", [_vm._v(_vm._s(_vm.t("Job anzeigen")))])
                : _c("span", [_vm._v(_vm._s(_vm.t("Jobs anzeigen")))])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isLoggedIn && !_vm.isArbeitgeber
        ? _c("div", { staticClass: "jobalarm-menu" }, [
            _c(
              "form",
              {
                on: {
                  submit: function(e) {
                    return _vm.jobalarm.createJobalarmFromSearch(
                      e,
                      _vm.filterArray
                    )
                  }
                }
              },
              [
                _c("div", { staticClass: "pb-4 border-color1 border-b" }, [
                  _c("h2", { staticClass: "text-f2 font-bold" }, [
                    _vm._v(_vm._s(_vm.t("Job-Alarm einrichten")))
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        _vm.t(
                          "Richte dir gemäss oben eingestellten Filtern deinen persönlichen Job Alarm ein!"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "py-4 border-color1 border-b" }, [
                  _c("div", { staticClass: "mb-6" }, [
                    _c("label", { attrs: { for: "name" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.t("Name Job-Alarm")) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "text-color7" }, [_vm._v("*")])
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.jobalarm.form.title,
                          expression: "jobalarm.form.title"
                        }
                      ],
                      staticClass:
                        "bg-color10 border-none placeholder:text-color1",
                      attrs: {
                        type: "text",
                        name: "name",
                        id: "name",
                        placeholder: _vm.t("Gib deinem Job-Alarm einen Namen"),
                        required: ""
                      },
                      domProps: { value: _vm.jobalarm.form.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.jobalarm.form,
                            "title",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _c("label", { attrs: { for: "haeufigkeit" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.t("E-Mail-Häufigkeit")) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "text-color7" }, [_vm._v("*")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-wrapper bg-color2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobalarm.form.haeufigkeit,
                              expression: "jobalarm.form.haeufigkeit"
                            }
                          ],
                          attrs: {
                            name: "haeufigkeit",
                            id: "haeufigkeit",
                            required: ""
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.jobalarm.form,
                                "haeufigkeit",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" }
                            },
                            [_vm._v(_vm._s(_vm.t("Häufigkeit auswählen")))]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.haeufigkeiten, function(
                            haeufigkeit,
                            index
                          ) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: haeufigkeit.id }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(haeufigkeit.title) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pt-4 text-center" }, [
                  _c("button", { staticClass: "btn" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("Job-Alarm speichern")) +
                        "\n          "
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoggedIn
        ? _c("div", { staticClass: "jobalarm-menu" }, [
            _c("div", { staticClass: "pb-4 border-color1 border-b" }, [
              _c("h2", { staticClass: "text-f2 font-bold" }, [
                _vm._v(_vm._s(_vm.t("Job-Alarm einrichten")))
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm._f("t")(
                      "Logge dich ein oder erstelle einen Account, um einen Jobalarm einzurichten."
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pt-4 text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn whitespace-nowrap",
                  on: {
                    click: function($event) {
                      return _vm.overlays.show("overlayLogin")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.t("Login")))]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dashboard-menu hidden menuBreak:block" },
      [
        _c("akkordeon", {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(_vm._s(_vm.t("Suche")))]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [
                  _c("div", { staticClass: "search-menu mb-0" }, [
                    _c("div", { staticClass: "pb-4 border-color8 border-b" }, [
                      _c("label", { attrs: { for: "stringSearch" } }, [
                        _vm._v(_vm._s(_vm.t("Suche")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "relative" }, [
                        _c("i", {
                          staticClass: "far fa-search search-input-icon"
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterArray.stringSearch,
                              expression: "filterArray.stringSearch"
                            }
                          ],
                          staticClass: "search-input",
                          attrs: {
                            type: "search",
                            id: "stringSearch",
                            name: "stringSearch",
                            placeholder: _vm.t("Suchbegriff eingeben"),
                            required: ""
                          },
                          domProps: { value: _vm.filterArray.stringSearch },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filterArray,
                                  "stringSearch",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.searchJobs()
                              }
                            ]
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "kartenStandort" } }, [
                          _vm._v(_vm._s(_vm.t("Ort")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative mb-4" },
                          [
                            _c("i", {
                              staticClass: "far fa-search search-input-icon"
                            }),
                            _vm._v(" "),
                            _c("vue-google-autocomplete", {
                              staticClass: "search-input",
                              attrs: {
                                id: "kartenStandort",
                                name: "kartenStandort",
                                placeholder: _vm.t("Ort oder PLZ"),
                                types: ["establishment"],
                                "component-restrictions": {
                                  country: ["ch", "li"]
                                }
                              },
                              on: {
                                place_changed: function(e) {
                                  return _vm.setStandortFilter(e)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(
                          "\n\n            " + _vm._s(_vm.t("Radius")) + ": "
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.filterArray.radius) + " km")
                        ]),
                        _vm._v(" "),
                        _c("vue-slider", {
                          on: {
                            change: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.radius,
                            callback: function($$v) {
                              _vm.$set(_vm.filterArray, "radius", $$v)
                            },
                            expression: "filterArray.radius"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.t("Kanton")))
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.kantone,
                            multiple: true,
                            placeholder: _vm.t("Kanton auswählen")
                          },
                          on: {
                            input: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.kanton,
                            callback: function($$v) {
                              _vm.$set(_vm.filterArray, "kanton", $$v)
                            },
                            expression: "filterArray.kanton"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.t("Funktion")))
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.funktionen,
                            multiple: true,
                            placeholder: _vm.t("Funktion auswählen")
                          },
                          on: {
                            input: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.funktion,
                            callback: function($$v) {
                              _vm.$set(_vm.filterArray, "funktion", $$v)
                            },
                            expression: "filterArray.funktion"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.t("Anstellungsgrad")))
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.arten,
                            multiple: true,
                            placeholder: _vm.t("Anstellungsgrad auswählen")
                          },
                          on: {
                            input: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.art,
                            callback: function($$v) {
                              _vm.$set(_vm.filterArray, "art", $$v)
                            },
                            expression: "filterArray.art"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.t("Berufserfahrung")))
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.erfahrungen,
                            multiple: true,
                            placeholder: _vm.t("Erfahrung auswählen")
                          },
                          on: {
                            input: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.erfahrung,
                            callback: function($$v) {
                              _vm.$set(_vm.filterArray, "erfahrung", $$v)
                            },
                            expression: "filterArray.erfahrung"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "py-4 border-color8 border-b" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.t("Veröffentlichung")))
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.veroeffentlichungen,
                            placeholder: _vm.t("Veröffentlichung auswählen"),
                            "allow-empty": false,
                            label: "name",
                            "track-by": "hours"
                          },
                          on: {
                            input: function($event) {
                              return _vm.searchJobs()
                            }
                          },
                          model: {
                            value: _vm.filterArray.veroeffentlichung,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.filterArray,
                                "veroeffentlichung",
                                $$v
                              )
                            },
                            expression: "filterArray.veroeffentlichung"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pt-4 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function($event) {
                              return _vm.showSearchedJobs()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.searchedJobs.length) +
                              "\n              "
                          ),
                          _vm.searchedJobs.length == 1
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.t("Job anzeigen")))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.t("Jobs anzeigen")))
                              ])
                        ]
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _vm.isLoggedIn && !_vm.isArbeitgeber
          ? _c("akkordeon", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(_vm._s(_vm.t("Job-Alarm")))]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "jobalarm-menu -m-8 rounded-t-none" },
                          [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function(e) {
                                    return _vm.jobalarm.createJobalarmFromSearch(
                                      e
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb-4 border-color1 border-b"
                                  },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "text-f2 font-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.t("Job-Alarm einrichten"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t(
                                            "Richte dir gemäss oben eingestellten Filtern deinen persönlichen Job Alarm ein!"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "py-4 border-color1 border-b"
                                  },
                                  [
                                    _c("div", { staticClass: "mb-6" }, [
                                      _c("label", { attrs: { for: "name" } }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.t("Name Job-Alarm")) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-color7" },
                                          [_vm._v("*")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.jobalarm.form.title,
                                            expression: "jobalarm.form.title"
                                          }
                                        ],
                                        staticClass:
                                          "bg-color10 border-none placeholder:text-color1",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          id: "name",
                                          placeholder: _vm.t(
                                            "Gib deinem Job-Alarm einen Namen"
                                          ),
                                          required: ""
                                        },
                                        domProps: {
                                          value: _vm.jobalarm.form.title
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.jobalarm.form,
                                              "title",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _c(
                                        "label",
                                        { attrs: { for: "haeufigkeit" } },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.t("E-Mail-Häufigkeit")
                                              ) +
                                              "\n                  "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-color7" },
                                            [_vm._v("*")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "select-wrapper bg-color2"
                                        },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.jobalarm.form
                                                      .haeufigkeit,
                                                  expression:
                                                    "jobalarm.form.haeufigkeit"
                                                }
                                              ],
                                              attrs: {
                                                name: "haeufigkeit",
                                                id: "haeufigkeit",
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.jobalarm.form,
                                                    "haeufigkeit",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.t(
                                                        "Häufigkeit auswählen"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.haeufigkeiten,
                                                function(haeufigkeit, index) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: haeufigkeit.id
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            haeufigkeit.title
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "pt-4 text-center" }, [
                                  _c("button", { staticClass: "btn" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("Job-Alarm speichern")) +
                                        "\n              "
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2152105898
              )
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoggedIn
          ? _c("akkordeon", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(_vm._s(_vm.t("Job-Alarm")))]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "jobalarm-menu -m-8 rounded-t-none" },
                          [
                            _c(
                              "div",
                              { staticClass: "pb-4 border-color1 border-b" },
                              [
                                _c("h2", { staticClass: "text-f2 font-bold" }, [
                                  _vm._v(_vm._s(_vm.t("Job-Alarm einrichten")))
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("t")(
                                        "Logge dich ein oder erstelle einen Account, um einen Jobalarm einzurichten."
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "pt-4 text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn whitespace-nowrap",
                                  on: {
                                    click: function($event) {
                                      return _vm.overlays.show("overlayLogin")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.t("Login")))]
                              )
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1604515516
              )
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "dashboard-content" }, [
      _c(
        "div",
        { staticClass: "jobs-list", attrs: { id: "scrollto" } },
        [
          _vm._l(_vm.shownJobs, function(job, index) {
            return [
              _c("div", { key: job.id, staticClass: "job" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "flex gap-4 items-center s:pb-4 s:border-b s:border-color10 s:w-full",
                    attrs: { href: job.url }
                  },
                  [
                    job.arbeitgeberLogo
                      ? _c("div", {
                          staticClass:
                            "bg-color6 h-24 w-24 bg-cover bg-center rounded-lg s:h-20 s:w-20",
                          style: {
                            "background-image":
                              "url(" + job.arbeitgeberLogo + ")"
                          }
                        })
                      : _c(
                          "div",
                          {
                            staticClass:
                              "bg-color5 h-24 w-24 bg-cover bg-center rounded-lg relative"
                          },
                          [
                            _c("span", { staticClass: "text-center center" }, [
                              _vm._v(_vm._s(job.arbeitgeberName))
                            ])
                          ]
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-f2 font-bold job-titel" },
                        [_vm._v(_vm._s(job.title))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex gap-4 items-center" }, [
                        _c("div", [_vm._v(_vm._s(job.funktion))]),
                        _vm._v(" "),
                        job.kanton
                          ? _c("div", [
                              _c("i", {
                                staticClass: "fas fa-map-marker-alt text-color5"
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(job.kanton) +
                                  "\n                "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex gap-4 items-center s:justify-end s:w-full"
                  },
                  [
                    !_vm.isArbeitgeber && !job.beworben
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            on: {
                              click: function($event) {
                                return _vm.bewerben(job.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.t("Jetzt bewerben")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    job.beworben
                      ? _c(
                          "button",
                          {
                            staticClass: "btn minL:hover:bg-color1",
                            attrs: { disabled: "" }
                          },
                          [_vm._v(_vm._s(_vm.t("Bereits beworben")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLoggedIn && !_vm.isArbeitgeber
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn-green btn-round flex items-center",
                            class: {
                              isNotFavorit: !job.favorit,
                              isFavorit: job.favorit
                            },
                            on: {
                              click: function($event) {
                                return _vm.merken(job)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa-heart text-f2 far hoverHeart"
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa-heart text-f2 fas normalHeart"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              index == 3 && !_vm.isArbeitgeber
                ? _c("div", { key: index, staticClass: "job-alarm-job" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-f1 font-bold leading-none" },
                        [_vm._v(_vm._s(_vm.t("Job-Suche einfach gemacht")))]
                      ),
                      _vm._v(" "),
                      _c("div", {}, [
                        _vm._v(
                          _vm._s(
                            _vm.t(
                              "Der Job-Alarm informiert Sie über neue passende Stellenangebote"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.isLoggedIn
                      ? _c(
                          "button",
                          {
                            staticClass: "btn whitespace-nowrap",
                            on: {
                              click: function($event) {
                                return _vm.overlays.show("overlayJobalarm")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.t("Job-Alarm einrichten")))]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn whitespace-nowrap",
                            on: {
                              click: function($event) {
                                return _vm.overlays.show("overlayLogin")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.t("Job-Alarm einrichten")))]
                        )
                  ])
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pages" }, [
        _c(
          "div",
          { staticClass: "page-switcher" },
          [
            _vm.getPage() > 1
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        _vm.changePage(_vm.getPage() - 1)
                      }
                    }
                  },
                  [_c("i", { staticClass: "far fa-arrow-left" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(3, function(index) {
              return [
                index + _vm.getPage() - 2 > 0 &&
                index + _vm.getPage() - 2 <=
                  Math.ceil(
                    _vm.getNumberOfProducts() / _vm.getProductsPerPage()
                  )
                  ? _c(
                      "button",
                      {
                        key: index,
                        staticClass: "page-numbers",
                        class: {
                          active: index + _vm.getPage() - 2 === _vm.getPage()
                        },
                        on: {
                          click: function($event) {
                            _vm.changePage(index + _vm.getPage() - 2)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(index + _vm.getPage() - 2) +
                            "\n          "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }),
            _vm._v(" "),
            _vm.getPage() <
            Math.ceil(_vm.getNumberOfProducts() / _vm.getProductsPerPage())
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        _vm.changePage(_vm.getPage() + 1)
                      }
                    }
                  },
                  [_c("i", { staticClass: "far fa-arrow-right" })]
                )
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }