import { VBaseService, VServices } from '@libTs/vue-base';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';
import { TranslationService } from './translation.service';
import { request } from 'http';
import { OverlaysService } from './overlays.service';
import { LoadingService } from './loading.service';

const SERVICES = VServices( {
  translation: TranslationService,
  overlays: OverlaysService,
  loading: LoadingService
} );

export class LoginService extends VBaseService {

  public form = {
    email: '',
    password: '',
    rememberMe: false
  };


  public isValid = true;
  public isLoading = false;
  public errorArray: Array<string> = [];


  
  public login(e) {
    e.preventDefault();

    this.isLoading = true;

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/user/login',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
        window.location.href = `${SITEURL}/arbeitgeber/dashboard`;
      }
    } )
    .catch( error => {
      this.isLoading = false;

      const message = error.response && error.response.data && error.response.data.frontendMessage ?
      error.response.data.frontendMessage : this.t('Email oder Passwort falsch.');

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: message,
          class: 'error'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });

  }

  public passwortVergessen(e) {
    e.preventDefault();

    const requestData = {
      ...this.form
    };

    SERVICES.loading.startLoading();
    axios.post(
      SITEURL + 'actions/infajobs/user/forgot-password',
      requestData
    )
    .then( response => {
      console.log( response );
      if ( response.status == 200 ) {
        this.isLoading = false;
  
        const flash = new CustomEvent( 'flash', {
          detail: {
            text: this.t('Falls Sie bei infajobs registriert sind, wurde Ihnen ein E-Mail zum Zurücksetzen des Passworts gesendet.'),
            class: 'success'
          }
        } );
        window.dispatchEvent( flash );

        SERVICES.overlays.hide();
      }
    } )
    .catch( error => {
      this.isLoading = false;

      const message = error.response && error.response.data && error.response.data.frontendMessage ?
      error.response.data.frontendMessage : this.t('Email oder Passwort falsch.');

      const flash = new CustomEvent( 'flash', {
        detail: {
          text: this.t('Falls Sie bei infajobs registriert sind, wurde Ihnen ein E-Mail zum Zurücksetzen des Passworts gesendet.'),
          class: 'success'
        }
      } );
      window.dispatchEvent( flash );
    } )
    .finally(() => {
      SERVICES.loading.stopLoading();
    });

  }


  public t(str: string): string {
    return SERVICES.translation.t(str);
  }

}