import { VBaseService, VServices } from '@libTs/vue-base';

export class LoadingService extends VBaseService {
  public isLoading = false;

  public startLoading(): void {
    this.isLoading = true;
  }

  public stopLoading(): void {
    this.isLoading = false;
  }

}