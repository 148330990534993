


















































































































































































































































































































































































































































































































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';
import { OverlaysService } from '../services/overlays.service';
import Multiselect from 'vue-multiselect'
import { BewerbungService } from '../services/bewerbung.service';
import VueSlider from 'vue-slider-component';
import Akkordeon from '../components/akkordeon.vue';

import 'vue-slider-component/theme/antd.css'
import { JobalarmService } from '../services/jobalarm.service';
import { JobService } from '../services/job.service';
import axios from 'axios';
import { BASEURL, SITEURL, CRAFT_LANGUAGE } from '@libTs/cms.globals';


const SERVICES = VServices({
  translation: TranslationService,
  overlays: OverlaysService,
  bewerbung: BewerbungService,
  jobalarm: JobalarmService,
  job: JobService
});

export default Vue.extend({
  name: 'jobs-search',
  components: {
    Multiselect,
    VueSlider,
    Akkordeon
  },
  props: {
    data: String,
    kantone: Array,
    funktionen: Array,
    arten: Array,
    erfahrungen: Array,
    haeufigkeiten: Array,
    isLoggedIn: Number,
    isArbeitgeber: Number,
  },
  data() {
    return {
      filterArray: {
        'stringSearch': '',
        'kanton': [],
        'standort': '',
        'breitengrad': null,
        'laengengrad': null,
        'radius': 50,
        'funktion': [],
        'art': [],
        'erfahrung': [],
        'veroeffentlichung': {
          name: 'Alle',
          hours: 0
        },
      },
      jobsData: [],
      shownJobs: [],
      allShownJobs: [],
      searchedJobs: [],
      veroeffentlichungen: [
        {
          name: 'Letzte Stunde',
          hours: 1
        },
        {
          name: 'Letzte 24 Stunden',
          hours: 24
        },
        {
          name: 'Letzte 7 Tage',
          hours: 168
        },
        {
          name: 'Letzte 14 Tage',
          hours: 336
        },
        {
          name: 'Letzte 30 Tage',
          hours: 720
        },
        {
          name: 'Alle',
          hours: 0
        },
      ],
      currentPage: 1,
      pages: 1,
      recordsPerPage: 12,
      jobalarm: SERVICES.jobalarm,
      overlays: SERVICES.overlays,
    };
  },
  mounted() {
    this.jobsData = JSON.parse(this.data);

    this.veroeffentlichungen = [
      {
        name: this.t('Letzte Stunde'),
        hours: 1
      },
      {
        name: this.t('Letzte 24 Stunden'),
        hours: 24
      },
      {
        name: this.t('Letzte 7 Tage'),
        hours: 168
      },
      {
        name: this.t('Letzte 14 Tage'),
        hours: 336
      },
      {
        name: this.t('Letzte 30 Tage'),
        hours: 720
      },
      {
        name: this.t('Alle'),
        hours: 0
      },
    ];

    this.filterArray.veroeffentlichung.name = this.t('Alle');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('kantone')) {
      this.filterArray.kanton = urlParams.get('kantone')?.split(',');
    }
    if(urlParams.get('jobarten')) {
      this.filterArray.art = urlParams.get('jobarten')?.split(',');
    }
    if(urlParams.get('funktionen')) {
      this.filterArray.funktion = urlParams.get('funktionen')?.split(',');
    }

    this.pages = Math.ceil(this.jobsData.length / this.recordsPerPage);

    this.searchJobs();

    this.allShownJobs = this.searchedJobs;
    this.changePage(this.currentPage);

    window.addEventListener( 'reload', ( e: any ) => {
      axios.get(SITEURL + 'actions/infajobs/jobs/get-jobs')
      .then(response => {
        this.jobsData = response.data;
        this.searchJobs();
        this.allShownJobs = this.searchedJobs;
        this.changePage(this.currentPage);
      })
      .catch( error => {

      });
    });
  },
  methods: {
    searchJobs() {
      this.searchedJobs = this.jobsData.filter((job: any) => {
        let matching = true;

        if(this.filterArray.stringSearch.length > 0) {
          if(!job.title.toLowerCase().includes(this.filterArray.stringSearch.toLowerCase())) {
            matching = false;
          }
        }

        if(this.filterArray.kanton.length > 0) {
          if(this.filterArray.kanton.indexOf(job.kanton) == -1) {
            matching = false;
          }
        }
        
        if(this.filterArray.funktion.length > 0) {
          if(this.filterArray.funktion.indexOf(job.funktion) == -1) {
            matching = false;
          }
        }

        if(this.filterArray.art.length > 0) {
          if(this.filterArray.art.indexOf(job.art) == -1) {
            matching = false;
          }
        }

        if(this.filterArray.erfahrung.length > 0) {
          if(this.filterArray.erfahrung.indexOf(job.erfahrung) == -1) {
            matching = false;
          }
        }

        if(this.filterArray.veroeffentlichung.hours > 0) {
          let now = new Date();
          now.setHours(now.getHours() - this.filterArray.veroeffentlichung.hours);

          let jobDate = new Date(Date.parse(job.veroeffentlichung));

          if(jobDate < now) {
            matching = false;
          }
        }

        if(this.filterArray.breitengrad != null && this.filterArray.laengengrad) {
          if(!this.arePointsNear({ 
            "lat": job.breitengrad, 
            "lng": job.laengengrad
          })) {
            matching = false;
          }
        }

        return matching;
      });
    },
    showSearchedJobs() {
      this.allShownJobs = this.searchedJobs;
      this.changePage(1);
    },
    getSearchFilters() {
      let searchFilters = [];
      if(this.filterArray.stringSearch.length > 0) {
        searchFilters.push(
          {
            label: this.filterArray.stringSearch,
            type: 'stringSearch'
          }
        );
      }

      if(this.filterArray.kanton.length > 0) {
        this.filterArray.kanton.forEach((kanton: string) => {
          searchFilters.push(
            {
              label: kanton,
              type: 'kanton'
            }
          );
        });
      }

      if(this.filterArray.standort.length > 0) {
        searchFilters.push(
          {
            label: this.filterArray.standort,
            type: 'standort'
          }
        );
      }

      if(this.filterArray.funktion.length > 0) {
        this.filterArray.funktion.forEach((funktion: string) => {
          searchFilters.push(
            {
              label: funktion,
              type: 'funktion'
            }
          );
        });
      }

      if(this.filterArray.art.length > 0) {
        this.filterArray.art.forEach((art: string) => {
          searchFilters.push(
            {
              label: art,
              type: 'art'
            }
          );
        });
      }

      if(this.filterArray.erfahrung.length > 0) {
        this.filterArray.erfahrung.forEach((erfahrung: string) => {
          searchFilters.push(
            {
              label: erfahrung,
              type: 'erfahrung'
            }
          );
        });
      }


      if(this.filterArray.veroeffentlichung.hours > 0) {
        searchFilters.push(
          {
            label: this.filterArray.veroeffentlichung.name,
            type: 'veroeffentlichung'
          }
        );
      }

      return searchFilters;

    },
    removeFilter(searchFilter: any) {
      if((typeof this.filterArray[searchFilter.type]) == 'string') {
        this.filterArray[searchFilter.type] = '';
      }
      if((typeof this.filterArray[searchFilter.type]) == 'object') {
        
        if(this.filterArray[searchFilter.type].hours) {
          this.filterArray[searchFilter.type] = {
            name: this.t('Alle'),
            hours: 0
          };
        }
        else {
          let index = this.filterArray[searchFilter.type].indexOf(searchFilter.label);
          this.filterArray[searchFilter.type].splice(index);
        }
      }

      this.searchJobs();
    },
    setStandortFilter(e) {
      this.filterArray.breitengrad = e.geometry.location.lat();
      this.filterArray.laengengrad = e.geometry.location.lng();

      this.searchJobs();
    },
    bewerben(jobId: number) {
      if(this.isLoggedIn) {
        SERVICES.bewerbung.bewerben(jobId);
      }
      else {
        SERVICES.overlays.show('overlayLogin');
      }
    },
    merken(job: any) {
      if(job.favorit) {
        SERVICES.job.removeFromMerkliste(job.id);
      } 
      else {
        SERVICES.job.addToMerkliste(job.id);
      }
    },
    arePointsNear(checkPoint: any) {
      var ky = 40000 / 360;
      var kx = Math.cos(Math.PI * this.filterArray.breitengrad / 180.0) * ky;
      var dx = Math.abs(this.filterArray.laengengrad - checkPoint.lng) * kx;
      var dy = Math.abs(this.filterArray.breitengrad - checkPoint.lat) * ky;
      return Math.sqrt(dx * dx + dy * dy) <= this.filterArray.radius;
    },

    getPage() {
      return this.currentPage;
    },

    getNumberOfProducts() {
      return this.allShownJobs.length;
    },
    
    getProductsPerPage() {
      return this.recordsPerPage;
    },

    changePage(page: Number) {
      let scrollUp = true;
      if(page == this.currentPage) {
        scrollUp = false;
      }

      this.currentPage = page;

      
      if(scrollUp) {
        const id = 'scrollto';
        const yOffset = -150; 
        const element = document.getElementById(id);
        if(element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'auto'});
        }
      }

      setTimeout(() => {
        this.shownJobs = this.searchedJobs.slice((this.currentPage - 1) * this.recordsPerPage, this.currentPage * this.recordsPerPage);
      }, 10);

      
    },

    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
