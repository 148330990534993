




















































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';


const SERVICES = VServices({
  translation: TranslationService,
});

export default Vue.extend({
  name: 'homeSearch',
  props: {
    jobarten: String,
    kantone: String,
    searchurl: String
  },
  data() {
    return {
      hoverJobarten: false,
      hoverKantone: false,
      jobartenData: [],
      kantoneData: [],
      selectedJobarten: [],
      selectedKantone: [],
    };
  },
  mounted() {
    this.jobartenData = JSON.parse(this.jobarten);
    this.kantoneData = JSON.parse(this.kantone);

    console.log(this.searchurl);
  },
  methods: {
    t(str: string) {
      return SERVICES.translation.t(str);
    },
    toggleJobart(jobart: string) {
      if(this.selectedJobarten.includes(jobart)) {
        var index = this.selectedJobarten.indexOf(jobart);
        if (index !== -1) {
          this.selectedJobarten.splice(index, 1);
        }
      }
      else {
        this.selectedJobarten.push(jobart);
      }
    },
    toggleKanton(kanton: string) {
      if(this.selectedKantone.includes(kanton)) {
        var index = this.selectedKantone.indexOf(kanton);
        if (index !== -1) {
          this.selectedKantone.splice(index, 1);
        }
      }
      else {
        this.selectedKantone.push(kanton);
      }
    },
    redirectToSearch() {
      let urlWithParams = this.searchurl + "?kantone=" + this.selectedKantone.join(',') + "&funktionen=" + this.selectedJobarten.join(',');
      window.location.replace(urlWithParams);
    }
  },
});
