





































import Vue from 'vue';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { VServices } from '@libTs/vue-base';
import { TranslationService } from '../services/translation.service';

const SERVICES = VServices({
  translation: TranslationService,
});

export default Vue.extend({
  name: 'lightbox',
  props: {
    bilder: String
  },
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      images: [],
      index: 0,
      open: false
    };
  },
  mounted() {
    this.images = JSON.parse(this.bilder);
    console.log(this.bilder);
  },
  methods: {
    nextImage() {
      this.index++;
      if (this.index >= this.images.length) {
        this.index = 0;
      }
    },
    previousImage() {
      this.index--;
      if (this.index < 0) {
        this.index = this.images.length - 1;
      }
    },
    t(str: string) {
      return SERVICES.translation.t(str);
    },
  },
});
